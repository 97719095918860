import PropTypes from "prop-types";
import { createContext, useEffect, useReducer } from "react";
import { Chance } from "chance";
import { LOGIN, LOGOUT } from "store/reducers/actions";
import authReducer from "store/reducers/auth";
import Loader from "components/Loader";
import * as url from "../services/url_helper";
import { ApiServices } from "../services/apiServices";
import { dispatchFunction, getItem, setItem } from "services/utils";
import { Constants } from "services/keyConstants";
import { isEmpty } from "lodash";

const chance = new Chance();

// constant
const initialState = {
  isLoggedIn: false,
  isInitialized: false,
  user: null,
};

const JWTContext = createContext(null);

export const JWTProvider = ({ children }) => {
  const [state, dispatch] = useReducer(authReducer, initialState);

  useEffect(() => {
    const init = async () => {
      try {
        let token = getItem(Constants.KEY_USER_TOKEN);
        let userData = getItem(Constants.KEY_USER_DATA);
        if (isEmpty(token) && isEmpty(userData)) {
          dispatch({
            type: LOGOUT,
          });
        } else {
          dispatch({
            type: LOGIN,
            payload: {
              isLoggedIn: true,
              user: userData,
            },
          });
        }
      } catch (err) {
        console.error(err);
        dispatch({
          type: LOGOUT,
        });
      }
    };

    init();
  }, []);

  const login = async (password, clientUserId) => {
    const isEmail = (str) =>
      /\b[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Z|a-z]{2,}\b/.test(str);
    let companyData = getItem(Constants.KEY_COMPANY_DATA);

    let reqObj = {
      orgId: companyData?._id,
      password,
      ...(isEmail(clientUserId) ? { email: clientUserId } : { clientUserId }),
    };
    const uri = url.SIGN_IN;
    return ApiServices.callServicePostWithBodyData(uri, reqObj).then(
      (response) => {
        if (response?.result?.type === "success") {
          let userData = {
            ...response?.result?.data,
            designationArr: response?.result?.designationArr,
          };
          setItem(Constants.KEY_USER_TOKEN, response?.result?.token);
          setItem(Constants.KEY_USER_DATA, userData);
          setItem(Constants.PERMISSIONDATA, response?.result?.permissionData);
          dispatch({
            type: LOGIN,
            payload: {
              isLoggedIn: true,
              user: response?.result?.data,
            },
          });
          // if (response?.result?.designationArr?.length > 1) {
          //   return response?.result;
          // } else {
          //   setItem(
          //     Constants.KEY_SELECTED_ROLE,
          //     response?.result?.designationArr?.[0] || ""
          //   );
          // }
        } else {
          return response?.result;
        }
      }
    );
  };

  const resetPassword = async (reqObj) => {
    const uri = url.SAVE_PASSWORD;

    try {
      const response = await ApiServices.callServicePostWithBodyData(
        uri,
        reqObj
      );
      dispatchFunction(response);
      if (response?.result?.type === "success") {
        let userData = {
          ...response?.result?.data,
          designationArr: response?.result?.designationArr,
        };
        setItem(Constants.KEY_USER_TOKEN, response?.result?.token);
        setItem(Constants.KEY_USER_DATA, userData);
        setItem(Constants.PERMISSIONDATA, response?.result?.permissionData);
        dispatch({
          type: LOGIN,
          payload: {
            isLoggedIn: true,
            user: response?.result?.data,
          },
        });
      } else {
        return response?.result;
      }
    } catch (error) {
      console.error("Error in resetPassword:", error);
    }
  };

  const updateRole = async (userData, selectedRole) => {
    setItem(Constants.KEY_SELECTED_ROLE, selectedRole);
    dispatch({
      type: LOGIN,
      payload: {
        isLoggedIn: true,
        user: userData,
      },
    });
  };

  const logout = () => {
    const companyData = getItem(Constants.KEY_COMPANY_DATA);
    const hostname = getItem(Constants.KEY_HOST_NAME);
    localStorage.clear();
    if (companyData) {
      setItem(Constants.KEY_COMPANY_DATA, companyData);
    }
    if (hostname) {
      setItem(Constants.KEY_HOST_NAME, hostname);
    }

    setItem(Constants.KEY_USER_TOKEN, null);
    setItem(Constants.KEY_USER_DATA, null);
    dispatch({ type: LOGOUT });
  };

  if (state.isInitialized !== undefined && !state.isInitialized) {
    return <Loader />;
  }

  return (
    <JWTContext.Provider
      value={{
        ...state,
        login,
        resetPassword,
        logout,
        resetPassword,
        updateRole,
      }}
    >
      {children}
    </JWTContext.Provider>
  );
};

JWTProvider.propTypes = {
  children: PropTypes.node,
};

export default JWTContext;
