import { lazy } from "react";

// project-imports
import Loadable from "components/Loadable";
import AuthGuard from "utils/route-guard/AuthGuard";
import MainLayout from "layout/MainLayout";

// render - pages
const HeaderField = Loadable(lazy(() => import("../HeaderField")));
const Theme = Loadable(lazy(() => import("../Theme")));
const AssignedStandardList = Loadable(
  lazy(() => import("pages/standards/AssignedStandardList"))
);
const StandardDetails = Loadable(
  lazy(() => import("pages/standards/StandardDetails"))
);
const QualityManualList = Loadable(lazy(() => import("../QualityManualList")));
const AddEditManual = Loadable(lazy(() => import("../AddEditManual")));
const Organogram = Loadable(lazy(() => import("../Organogram")));
const InteractionOfProcess = Loadable(lazy(() => import("../InteractionOfProcess")));
// Helper function to create routes
const createRoute = (path, element, children) => ({ path, element, children });

const QualityManualRoutes = createRoute(
  "/",
  <AuthGuard>
    <MainLayout />
  </AuthGuard>,
  [
    createRoute("standards", null, [
      createRoute("header-field", <HeaderField />),
      createRoute("themes", <Theme />),
      createRoute("standard-list", <AssignedStandardList />),
      createRoute("standard-details", <StandardDetails />),
      createRoute("quality-manual", <QualityManualList />),
      createRoute("add-edit-manual", <AddEditManual />),
      createRoute("organogram", <Organogram />),
      createRoute("interactionOfProcess", <InteractionOfProcess />),
    ]),
  ]
);

export default QualityManualRoutes;
