import { Box, Card, Grid, Skeleton, Typography } from "@mui/material";
import MainCard from "components/MainCard";
import React, { useState } from "react";
import { useNavigate } from "react-router";
import FolderIcon from "@mui/icons-material/Folder";
import withRouter from "components/withRouter";

const PreventiveMaintenanceList = (props) => {
  const { params } = props?.router;
  const [data, setData] = useState([
    { name: "Preventive Maintenance Plan" },
    { name: "Machine BreakDown Report" },
  ]);
  const navigate = useNavigate();
  const year = params?.year;

  const handleOpenFolder = (documentName) => {
    {
      documentName === "Preventive Maintenance Plan"
        ? navigate(`/preventive-maintenance/maintenance-report-list/${year}`)
        : navigate(`/preventive-maintenance/machine-break-downList/${year}`);
    }
  };

  return (
    <MainCard title="Preventive Maintenance List">
      <Grid container spacing={2}>
        {data?.map((row, index) => (
          <Grid item xs={12} sm={6} md={4} lg={3} xl={2} key={index}>
            <Card
              style={{
                width: "100%",
                height: "100%",
                cursor: "pointer",
              }}
            >
              <Box
                onClick={() => handleOpenFolder(row?.name)}
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  textAlign: "center",
                  borderRadius: 2,
                  padding: 2,
                  height: "100%",
                  justifyContent: "center",
                  cursor: "pointer",
                  "&:hover": {
                    transform: "scale3d(1.02, 1.02, 1)",
                    transition: "all .10s ease-in-out",
                  },
                }}
              >
                <FolderIcon
                  sx={{
                    fontSize: 50,
                    color: "#5c8ee0",
                    cursor: "pointer",
                  }}
                />
                <Typography>{row?.name}</Typography>
              </Box>
            </Card>
          </Grid>
        ))}
      </Grid>
    </MainCard>
  );
};

export default withRouter(PreventiveMaintenanceList);
