import { lazy } from "react";

// project-imports
import Loadable from "components/Loadable";
import AuthGuard from "utils/route-guard/AuthGuard";
import MainLayout from "layout/MainLayout";
// import ComplaintTables from "../ComplaintTables";

const AddCustomerComplaint = Loadable(
  lazy(() => import("../AddCustomerComplaint"))
);
const ComplaintTables = Loadable(lazy(() => import("../ComplaintTables")));
const AssignCustomerComplaint = Loadable(
  lazy(() => import("../AssignCustomerComplaint"))
);
const CustomerView = Loadable(lazy(() => import("../CustomerView")));
const List = Loadable(lazy(() => import("../List")));
const YearList = Loadable(lazy(() => import("../YearList")));

const createRoute = (path, element, children) => ({ path, element, children });

const CCRoutes = createRoute(
  "/",
  <AuthGuard>
    <MainLayout />
  </AuthGuard>,
  [
    createRoute("customer-complaint", null, [
      createRoute("add-complaint", <AddCustomerComplaint />),
      createRoute("assign-customer-complaint", <AssignCustomerComplaint />),
      createRoute("customerComplaint/:year", <CustomerView />),
      createRoute("list/:year", <List />),
      createRoute("complaint-table/:year", <ComplaintTables />),
      createRoute("year-list", <YearList />),
    ]),
  ]
);

export default CCRoutes;
