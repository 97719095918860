import { useEffect, useState } from "react";
import {
  Box,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  MenuItem,
  TextField,
  Select,
  Skeleton,
  Grid,
} from "@mui/material";
import MainCard from "components/MainCard";
import * as url from "./utils/urlHelper";
import { downloadFormat, getTitle, showToast } from "services/utils";
import DownloadModal from "components/reusables/DownloadModal";
import { CustomHooks } from "hooks/CustomHooks";
import withRouter from "components/withRouter";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import SkeletonTable from "components/SkeletonTable";

const MasterListOfDoc = (props) => {
  const {
    userData,
    ApiServices,
    handleGetPermission,
    Constants,
    onChangeDownloadState,
  } = props;
  const { documentId } = CustomHooks.useUrlParams();
  const [manuals, setManuals] = useState([]);
  const [nextReviewDate, setNextReviewDate] = useState([]);
  const [reviewDate, setReviewDate] = useState([]);
  const [freqArray, setFreqArray] = useState([]);
  const [isDownload, setIsDownload] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const handleFreqChange = (index, value, date) => {
    const newFreqArray = [...freqArray];
    newFreqArray[index] = value;
    setFreqArray(newFreqArray);
    calculateNextReviewDate(date, index, value);
  };

  const calculateNextReviewDate = (date, index, value) => {
    if (!date || typeof date !== "string" || value === "") {
      console.error("Invalid date:", date);
      return;
    }
    let splitDate = date.includes("-") ? date.split("-") : date.split("/");
    if (splitDate.length !== 3) {
      console.error("Date splitting issue:", splitDate);
      return;
    }

    let day = parseInt(splitDate[2], 10);
    let month = parseInt(splitDate[1], 10);
    let year = parseInt(splitDate[0], 10);

    if (value === "0.5") {
      month += 6;
      if (month > 12) {
        month -= 12;
        year += 1;
      }
    } else {
      year += parseInt(value, 10);
    }

    // Format the new date
    let newJoinedDate = date.includes("-")
      ? `${year}-${String(month).padStart(2, "0")}-${String(day).padStart(
          2,
          "0"
        )}`
      : `${year}/${String(month).padStart(2, "0")}/${String(day).padStart(
          2,
          "0"
        )}`;

    // Update the state
    setNextReviewDate((prevDates) => {
      const updatedDates = [...prevDates];
      updatedDates[index] = newJoinedDate;
      return updatedDates;
    });
  };

  const handleFormatDataSave = () => {
    let formattedData = manuals?.map((row, index) => {
      const freqValue =
        freqArray[index] === ""
          ? ""
          : `${freqArray[index]} ${freqArray[index] > 1 ? "years" : "year"}`;
      const nextReviewDateValue =
        nextReviewDate[index] == undefined ? "" : nextReviewDate[index];
      return {
        srNo: index + 1,
        documentNumber: row?.documentNumber,
        documentName: row?.documentName,
        revisionNumber: row?.revisionNumber,
        effectiveDate: row?.effectiveDate,
        frequency: freqValue,
        nextReviewDate: nextReviewDateValue,
        reviewDate: reviewDate[index],
      };
    });
    updateDocument(formattedData);
  };

  const handleReviewDateChange = (index, newDate) => {
    const newReviewDates = [...reviewDate];
    newReviewDates[index] = newDate;
    setReviewDate(newReviewDates);
  };

  const convertToDateInputFormat = (dateString) => {
    const [day, month, year] = dateString.split("-");
    return `${day}-${month}-${year}`;
  };

  const updateDocument = async (formattedData) => {
    try {
      let reqObj = {
        document: formattedData,
      };
      let uri = url.UPDATE_MASTER_LIST + documentId;
      let result = await ApiServices.callServicePutWithBodyData(uri, reqObj);
      showToast(result);
      if (result?.meta?.success) {
        getDocument();
      }
    } catch (error) {
      console.error("GET_CHILD_FORMATS_DATA_BY_FORMATNUMBER ERROR", error);
    }
  };

  const cleanFrequency = (frequency) => {
    if (frequency === undefined) return "";
    const match = frequency?.match(/^\d+(\.\d+)?/);
    return match ? match[0] : "";
  };

  const calculateNextReviewDateInitial = () => {
    manuals.forEach((manual, index) => {
      const effectiveDate = manual?.effectiveDate;
      const freqValue = freqArray[index];
      if (freqValue) {
        calculateNextReviewDate(effectiveDate, index, freqValue);
      }
    });
  };

  const handleDownload = async () => {
    let reqObj = {
      formatId: documentId,
    };
    let uri = url.DOWNLOAD_COD;
    await downloadFormat(uri, reqObj, onChangeDownloadState);
  };

  const getDocument = async () => {
    try {
      let reqObj = {
        childformatId: documentId,
        documentName: "Master List Of Document",
      };
      let uri = url.GET_MASTER_LIST;
      let result = await ApiServices.callServicePostWithBodyData(uri, reqObj);
      if (result?.meta?.success) {
        const fetchedManuals = result?.data.document || [];
        const frequencies = fetchedManuals?.map(
          (manual) => cleanFrequency(manual?.frequency) || ""
        );
        const reviewDates = fetchedManuals?.map((manual) =>
          manual?.reviewDate ? convertToDateInputFormat(manual?.reviewDate) : ""
        );

        const updatedManuals = result?.data?.document?.map((manual, index) => ({
          ...manual,
          reviewDate: reviewDates[index],
          frequency: frequencies[index],
        }));
        setManuals(updatedManuals);
        setFreqArray(frequencies);
        setReviewDate(reviewDates);
        calculateNextReviewDateInitial();
      }
    } catch (error) {
      console.error("GET_CHILD_FORMATS_DATA_BY_FORMATNUMBER ERROR", error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getDocument();
  }, []);

  return (
    <MainCard
      title={getTitle("Master List of Docs")}
      secondary={
        isLoading ? (
          <Skeleton
            variant="rectangular"
            height="30px"
            width="100px"
            sx={{
              borderRadius: "12px",
            }}
          />
        ) : (
          handleGetPermission(
            "Master List of Documnets - COD",
            Constants.KEY_RBAC_DOWNLOAD
          ) && (
            <Button
              variant="contained"
              color="success"
              onClick={handleDownload}
            >
              Download
            </Button>
          )
        )
      }
    >
      <Grid container spacing={2}>
        {isLoading ? (
          <Grid item xs={12}>
            <SkeletonTable />
          </Grid>
        ) : (
          <>
            <Grid item xs={12}>
              <TableContainer component={Paper}>
                <Table aria-label="document list table">
                  <TableHead>
                    <TableRow>
                      <TableCell>Sr. No.</TableCell>
                      <TableCell>Document No.</TableCell>
                      <TableCell>Document Title</TableCell>
                      <TableCell>Rev. No.</TableCell>
                      <TableCell>Effective Date</TableCell>
                      <TableCell>Frequency of Review</TableCell>
                      <TableCell>Next Review Date</TableCell>
                      <TableCell>Review Date</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {manuals?.map((row, index) => (
                      <TableRow key={index}>
                        <TableCell>{index + 1}</TableCell>
                        <TableCell>{row?.documentNumber}</TableCell>
                        <TableCell>{row?.documentName}</TableCell>
                        <TableCell>{row?.revisionNumber}</TableCell>
                        <TableCell>{row?.effectiveDate}</TableCell>
                        <TableCell>
                          <Select
                            value={freqArray[index] || ""}
                            onChange={(e) =>
                              handleFreqChange(
                                index,
                                e.target.value,
                                row.effectiveDate
                              )
                            }
                            sx={{
                              "& .Mui-disabled": {
                                WebkitTextFillColor: "black",
                              },
                            }}
                            disabled={
                              userData?.externalUser || !row?.effectiveDate
                                ? true
                                : false
                            }
                            displayEmpty
                            fullWidth
                          >
                            <MenuItem value="">
                              <em>Select Frequency</em>
                            </MenuItem>
                            <MenuItem value="0.5">6 months</MenuItem>
                            <MenuItem value="1">1 year</MenuItem>
                            <MenuItem value="2">2 years</MenuItem>
                            <MenuItem value="3">3 years</MenuItem>
                            <MenuItem value="4">4 years</MenuItem>
                            <MenuItem value="5">5 years</MenuItem>
                          </Select>
                        </TableCell>
                        <TableCell>{nextReviewDate[index] || ""}</TableCell>
                        <TableCell>
                          <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DatePicker
                              fullWidth
                              sx={{ width: "100%" }}
                              name="date"
                              value={
                                reviewDate[index]
                                  ? dayjs(reviewDate[index])
                                  : null
                              }
                              onChange={(e) => handleReviewDateChange(index, e)}
                              disabled={userData?.externalUser ? true : false}
                            />
                          </LocalizationProvider>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
            {!userData?.externalUser &&
              handleGetPermission(
                "Master List of Documnets - COD",
                Constants.KEY_RBAC_CREATE
              ) && (
                <Grid item xs={12}>
                  <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                    <Button
                      onClick={handleFormatDataSave}
                      variant="contained"
                      color="primary"
                    >
                      Save
                    </Button>
                  </Box>
                </Grid>
              )}
          </>
        )}
      </Grid>
      <DownloadModal isOpen={isDownload} setIsClose={setIsDownload} />
    </MainCard>
  );
};

export default withRouter(MasterListOfDoc);
