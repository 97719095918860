const baseUrl = "/advisory-recall";

let productBaseUrl = "/product";

export const CREATER = `${baseUrl}/`;

export const GET_PRO_FORMAT_DATA = `${baseUrl}/pro-format/`;

export const GET_FORMAT_DATA = `${baseUrl}/format/`;

export const POST_PRO_FORMAT_PRODUCT_RECALL_REGISTER = `${baseUrl}/pro-format`;

export const GET_RETURNED_GOODS_RECORDS = `${baseUrl}/format/return-of-goods/`;

export const DOWNLOAD_DOCUMENT = `${baseUrl}/format/download`;

export const CREATE_ADVISORY_NOTICE = `${baseUrl}/notice`;

export const GET_ALL_ADVISORY_NOTICE = `${baseUrl}/notice`;

export const GET_ALL_ADVISORY_NOTICE_BY_ID = `${baseUrl}/notice/`;

export const UPDATE_ADVISORY_NOTICE_BY_ID = `${baseUrl}/notice/`;

export const UPDATE_ADVISORY_NOTICE_SPECIFIC = `${baseUrl}/notice/specific/`;

export const GET_BY_YEAR = `${baseUrl}/pro-format/get-by-year`;

export const GET_PRODUCTS = `${productBaseUrl}/get-all`;

export const NOTICE_DOWNLOAD = `${baseUrl}/notice/download`;
