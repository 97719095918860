import { lazy } from "react";

// project-imports
import Loadable from "components/Loadable";
import AuthGuard from "utils/route-guard/AuthGuard";
import MainLayout from "layout/MainLayout";

// render - pages
const ProcedureList = Loadable(lazy(() => import("../ProcedureList")));
const ProcedureDetails = Loadable(lazy(() => import("../ProcedureDetails")));
const AddEditProcedure = Loadable(lazy(() => import("../AddEditProcedure")));
const ProcedureDashboard = Loadable(lazy(() => import("../ProcedureDashboard")));
const VersionList = Loadable(lazy(() => import("../VersionList")));

// Helper function to create routes
const createRoute = (path, element, children) => ({ path, element, children });

const ProcedureRoutes = createRoute(
  "/",
  <AuthGuard>
    <MainLayout />
  </AuthGuard>,
  [
    createRoute("standards", null, [
      createRoute("procedure-list", <ProcedureList />),
      createRoute("procedure-details", <ProcedureDetails />),
      createRoute("addEdit-procedure", <AddEditProcedure />),
      createRoute("procedure-dashboard", <ProcedureDashboard />),
      createRoute("procedure-version-list", <VersionList />),

    ]),
  ]
);

export default ProcedureRoutes;
