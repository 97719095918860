const baseUrl = "/customer-service";

// MAM of products
export const POST_CUSTOMER_SERVICING = `${baseUrl}/`;
export const GET_BY_ID_CUSTOMER = `${baseUrl}/`;
export const PUBLIC_FORM = `${baseUrl}/public`;
export const GET_ALL_SERVICE_FEEDBACK = `${baseUrl}/get-dynamic-document`;
export const UPDATE_SPECIFIC_DATA = `${baseUrl}/update-specific`;
export const DOWNLOAD = `${baseUrl}/download`;

export const GET_YEAR_LIST = `${baseUrl}/get-by-year`;
