import PropTypes from "prop-types";
import { useState } from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  Switch,
} from "@mui/material";
import { Logout, Musicnote, Profile, RecoveryConvert } from "iconsax-react";
import { getItem, setItem } from "services/utils";
import withRouter from "components/withRouter";

const ProfileTab = (props) => {
  const { handleToggle, handleLogout, theme, userData, userRole, Constants } =
      props,
    { navigate } = props?.router;
  const [selectedIndex, setSelectedIndex] = useState(0);
  const storedSoundOn = getItem(Constants.KEY_NOTIFICATION_SOUND);

  const [soundOn, setSoundOn] = useState(
    storedSoundOn !== undefined ? storedSoundOn : true
  );

  const handleListItemClick = (event, index, route = "") => {
    setSelectedIndex(index);
    if (route && route !== "") {
      navigate(route);
    }
  };

  const handleRoleSelection = (role) => {
    setItem(Constants.KEY_SELECTED_ROLE, role);
    window.dispatchEvent(new Event("roleUpdated"));
  };

  const handleSoundToggle = () => {
    const newSoundOn = !soundOn;
    setSoundOn(newSoundOn);

    setItem(Constants.KEY_NOTIFICATION_SOUND, newSoundOn);
    window.dispatchEvent(new Event("notificationSoundUpdated"));
  };

  return (
    <>
      <List
        component="nav"
        sx={{ p: 1, "& .MuiListItemIcon-root": { minWidth: 32 } }}
      >
        {userData?.designationArr?.length > 1 ? (
          <Accordion
            sx={{
              border: "none",
              borderRadius: "8px",
              overflow: "hidden",
              background: "none",
              margin: "2px 0",
              "&.Mui-expanded": {
                border: "1px solid #dedede",
              },
              "&:not(.Mui-expanded)": {
                border: "none",
              },
            }}
          >
            <AccordionSummary
              expandIcon={<RecoveryConvert size={20} />}
              sx={{ background: "none" }}
            >
              Switch Designation
            </AccordionSummary>

            <AccordionDetails
              sx={{
                margin: "0 10px",
                padding: 0,
                overflowY: "scroll",
                maxHeight: "300px",
                overflow: "scroll",
                "&::-webkit-scrollbar": {
                  width: "2px",
                },
                "&::-webkit-scrollbar-thumb": {
                  backgroundColor: theme.palette.primary.main,
                  borderRadius: "10px",
                },
              }}
            >
              {userData?.designationArr?.map((elm, index) => (
                <List sx={{ padding: 0 }} key={index}>
                  <ListItemButton onClick={() => handleRoleSelection(elm)}>
                    <ListItemText primary={elm} />
                  </ListItemButton>
                </List>
              ))}
            </AccordionDetails>
          </Accordion>
        ) : null}
        <ListItemButton
          selected={selectedIndex === 2}
          onClick={(event) => {
            handleToggle();
            handleListItemClick(event, 2, "/profile");
          }}
        >
          <ListItemIcon>
            <Profile variant="Bulk" size={18} />
          </ListItemIcon>
          <ListItemText primary="View Profile" />
        </ListItemButton>
        <ListItemButton
          selected={selectedIndex === 3}
          onClick={(event) => {
            handleSoundToggle();
            handleListItemClick(event, 3);
          }}
        >
          <ListItemIcon>
            <Musicnote variant="Bulk" size={18} />
          </ListItemIcon>
          <ListItemText primary="Notification Sound" />
          <ListItemSecondaryAction>
            <Switch
              edge="end"
              checked={soundOn}
              inputProps={{ "aria-labelledby": "switch-list-label-sound" }}
            />
          </ListItemSecondaryAction>
        </ListItemButton>
        <ListItemButton
          sx={{
            "&:hover": {
              backgroundColor: "#f591915c",
            },
          }}
          selected={selectedIndex === 4}
          onClick={handleLogout}
        >
          <ListItemIcon>
            <Logout color="red" variant="Bulk" size={18} />
          </ListItemIcon>
          <ListItemText primary="Logout" />
        </ListItemButton>
      </List>
    </>
  );
};

ProfileTab.propTypes = {
  handleLogout: PropTypes.func,
};

export default withRouter(ProfileTab);
