import { lazy } from "react";
import Loadable from "components/Loadable";
import AuthGuard from "utils/route-guard/AuthGuard";
import MainLayout from "layout/MainLayout";
import ServiceRecordHistory from "../ServiceRecordHistory";
import CustomerServivingForm from "../CustomerServicingForm";

// Define lazy-loaded components
const ServiceHistory = Loadable(lazy(() => import("../ServiceHistory")));
const ServiceRecord = Loadable(lazy(() => import("../ServiceRecord")));
const ServiceList = Loadable(lazy(() => import("../ServiceList")));
const ServiceYearList = Loadable(lazy(() => import("../ServiceYearList")));
const ServiceLink = Loadable(lazy(() => import("../ServiceLink")));



// Helper function to create routes
const createRoute = (path, element, children) => ({ path, element, children });

// Define routes using lazy-loaded components
const ServicingRoutes = createRoute(
  "/",
  <AuthGuard>
    <MainLayout />
  </AuthGuard>,
  [
    createRoute("servicing", null, [
      createRoute("service-record/:id", <ServiceRecord />),
      createRoute("service-history", <ServiceHistory />),
      createRoute("service-record-history/:year", <ServiceRecordHistory />),
      createRoute("service-list/:year", <ServiceList />),
      createRoute("year-list", <ServiceYearList />),
      createRoute("customer-service-form", <CustomerServivingForm />),
      createRoute("customer-service-form/:id", <CustomerServivingForm />),
      createRoute("service-link", <ServiceLink />),
    ]),
  ]
);

export default ServicingRoutes;
