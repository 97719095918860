import React, { useEffect, useRef, useState } from "react";
import io from "socket.io-client";
import {
  Badge,
  Box,
  ClickAwayListener,
  List,
  ListItemButton,
  ListItemAvatar,
  ListItemText,
  ListItemSecondaryAction,
  Paper,
  Popper,
  Stack,
  Typography,
  Snackbar,
  Skeleton,
  Alert,
  Button,
} from "@mui/material";
import MainCard from "components/MainCard";
import IconButton from "components/@extended/IconButton";
import Transitions from "components/@extended/Transitions";
import { Notification, TickCircle, Trash } from "iconsax-react";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import NotificationStyle from "components/NotificationStyle";
import { format, isValid } from "date-fns";
import { setItem } from "services/utils";
import Avatar from "components/@extended/Avatar";
import CustomTooltip from "components/reusables/CustomTooltip";
import withRouter from "components/withRouter";
import { ApiServices } from "services/apiServices";

const NotificationPage = (props) => {
  const { theme, service, userData, Constants } = props,
    { navigate } = props?.router,
    { ThemeMode } = props?.config;
  const [isLoading, setIsLoading] = useState(true);
  const anchorRef = useRef(null);
  const socketRef = useRef(null);
  const [open, setOpen] = useState(false);
  const [notifications, setNotifications] = useState([]);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [tempNotification, setTempNotification] = useState(null);
  const [hoveredNotification, setHoveredNotification] = useState(null);
  const user = userData;

  // Update the formatDate function
  const formatDate = (timestamp) => {
    const date = timestamp instanceof Date ? timestamp : new Date(timestamp);

    if (!isValid(date)) {
      return { time: "Invalid date", formattedDate: "Invalid date" };
    }
    return {
      time: format(date, "hh:mm a"),
      formattedDate: format(date, "do MMMM yyyy"),
    };
  };

  const handleNotification = async () => {
    setIsLoading(true);
    try {
      const reqObj = { clientUserId: user?._id };
      let uri = props?.commonUrl?.GET_NOTIFICATIONS;
      let result = await props?.ApiServices.callServicePostWithBodyData(
        uri,
        reqObj
      );
      if (result?.success) {
        setNotifications(result?.notifications?.notifications?.reverse());
      }
    } catch (error) {
      console.error("Error fetching notifications:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const countMarkedCompleteFalse = notifications?.reduce((acc, obj) => {
    if (!obj.markedComplete) {
      acc++;
    }
    return acc;
  }, 0);

  useEffect(() => {
    handleNotification();

    if (socketRef.current) {
      socketRef.current.off("new_msg");
      socketRef.current.off("disconnect");
      socketRef.current.off("reconnect");
      socketRef.current.close();
    }

    const socket = io(service.IMG_URL, {
      transports: ["websocket", "polling"],
      reconnection: true,
      secure: true, // Ensure secure WebSocket connection
      reconnectionAttempts: Infinity,
      reconnectionDelay: 2000,
    });

    socketRef.current = socket;

    const userId = user?._id;

    const joinRoom = () => {
      socket.emit("join", { id: userId });
      console.log(`Joining room with ID: ${userId}`);
    };

    joinRoom(); // Join room initially

    socket.on("new_msg", (data) => {
      if (
        data?.title === "New Permissions Assign" ||
        data?.title === "Permissions Deleted" ||
        data?.title === "Permissions Updated" ||
        data?.title === "Other Permissions Assign"
      ) {
        setPermissions();
      }

      getChangeNoteById(data?._id);
      const newNotification = {
        title: data?.title,
        message: data?.message,
        timestamp: new Date(),
        location: data?.location,
        _id: data?._id,
      };

      setTempNotification(newNotification);
      setNotifications((prevNotifications) => [
        newNotification,
        ...prevNotifications,
      ]);
    });

    socket.on("disconnect", () => {
      console.log("Socket disconnected. Attempting to reconnect...");
      joinRoom(); // Rejoin the room after reconnection
    });

    socket.on("reconnect", () => {
      console.log("Socket reconnected successfully.");
      joinRoom(); // Rejoin the room after reconnection
    });

    return () => {
      if (socketRef.current) {
        socketRef.current.off("new_msg");
        socketRef.current.off("disconnect");
        socketRef.current.off("reconnect");
        socketRef.current.close();
      }
    };
  }, [user?._id, service.IMG_URL]);

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
  };

  const setPermissions = async () => {
    try {
      const reqObj = {
        clientUserId: userData?._id,
      };

      const uri = "/role/get-permission-list";
      const result = await ApiServices.callServicePostWithBodyData(uri, reqObj);

      if (result?.success) {
        setItem(Constants.PERMISSIONDATA, JSON.stringify(result?.data));
      } else {
        console.error("Failed to fetch permissions:", result?.message);
      }
    } catch (error) {
      console.error("Error fetching permissions:", error);
    }
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };
  const iconBackColorOpen =
    theme.palette.mode === ThemeMode.DARK ? "secondary.200" : "secondary.200";
  const iconBackColor =
    theme.palette.mode === ThemeMode.DARK
      ? "background.default"
      : "secondary.100";

  const actionSX = {
    mt: "6px",
    ml: 1,
    top: "auto",
    right: "auto",
    alignSelf: "flex-start",
    transform: "none",
  };

  const handleNotificationPage = (notification) => {
    if (notification?.metaInfo?.isThroughState) {
      navigate(notification?.location, notification?.metaInfo?.state);
      return;
    }
    if (notification?.metaInfo?.openModal) {
      if (notification?.metaInfo?.id) {
        setItem(Constants.TEMPID, notification?.metaInfo?.id);
      } else if (notification?.metaInfo?.isMultiId) {
        setItem(Constants.TEMPID, notification?.metaInfo?.multipleIds);
      }
      window.dispatchEvent(new Event(`${notification?.metaInfo?.modalName}`));
      return;
    }
    if (notification?.location === "changeNoteHistory") {
      navigate(notification?.location, {
        state: {
          changeNoteId: notification?._id,
        },
      });
      getChangeNoteById(notification?._id);
    } else if (notification?.location === "feedback") {
      navigate(`/${notification?.location}/${notification?._id}`);
    } else {
      navigate(notification?.location, {
        state: {
          travelId: notification?._id,
        },
      });
    }
  };

  const getChangeNoteById = async (changeNoteId) => {
    if (changeNoteId?.length > 0 && changeNoteId !== null) {
      try {
        let uri = props?.commonUrl?.GET_CHANGE_NOTE_BY_ID + changeNoteId;
        let result = await props?.ApiServices.callServiceGet(uri);
        if (result?.result?.type === "success") {
          if (
            result?.result?.data?.changeNoteData?.currentStatus ===
              "Document Approval" ||
            result?.result?.data?.changeNoteData?.currentStatus ===
              "Change request note approved"
          ) {
            localStorage.removeItem(Constants.CHANGENOTEDATA);
          } else {
            let changeNoteObj = result?.result?.data?.changeNoteData;
            changeNoteObj.changeNoteId = result?.result?.data?._id;
            setItem(Constants.CHANGENOTEDATA, JSON.stringify(changeNoteObj));
          }
        }
      } catch (error) {
        console.error("GET_CHANGE_NOTE_BY_ID API", error);
      }
    }
  };

  // Mapping of notification types to border colors
  const avatarBorderColors = {
    Submitted: "#12372A",
    Approved: "#820300",
    "Requested Approval": "blue",
    "Requested Review": "black",
    "Requested Initiated": "#2f3645",
  };

  const ListItemButtonTypes = {
    Submitted: {
      backgroundColor:
        theme.palette.mode === "dark" ? "#1e5d1e75" : "#c5ff9b8a",
      hoverColor: "#b3d69e",
      icon: (
        <TickCircle
          style={{
            color: theme.palette.mode === "dark" ? "#07d400" : "#00b500",
          }}
          variant="Bold"
          size="20"
        />
      ),
    },
    Approved: {
      backgroundColor:
        theme.palette.mode === "dark" ? "#1d1da64a" : "#dce7ffb3",
      hoverColor: "#e4c6c0",
      icon: (
        <CheckCircleIcon
          sx={{ color: theme.palette.mode === "dark" ? "#fff" : "#00b500" }}
          variant="Bold"
          fontSize="large"
        />
      ),
    },
  };

  const handleMouseEnter = (notification) => {
    setHoveredNotification(notification);
  };

  const handleMouseLeave = () => {
    setHoveredNotification(null);
  };

  const handleDeleteAll = async () => {
    let uri = props?.commonUrl?.Delete_All_Notifications;
    let result = await props?.ApiServices.callServiceDelete(uri);
    if (result) {
      setNotifications([]);
    }
  };

  const DeleteOneNotification = async (uniqueId) => {
    let uri = props?.commonUrl?.Delete_Notification_By_Id + uniqueId;
    let result = await props?.ApiServices.callServiceDelete(uri);
    if (result?.success) {
      handleNotification();
    }
  };

  return (
    <Box sx={{ flexShrink: 0, ml: 0.5 }}>
      <IconButton
        color="secondary"
        variant="light"
        aria-label="open profile"
        ref={anchorRef}
        aria-controls={open ? "profile-grow" : undefined}
        aria-haspopup="true"
        onClick={() => setOpen((prevOpen) => !prevOpen)}
        size="large"
        sx={{
          color: "secondary.main",
          bgcolor: open ? iconBackColorOpen : iconBackColor,
          p: 1,
        }}
      >
        <Badge
          badgeContent={countMarkedCompleteFalse ?? 0}
          color="success"
          sx={{ "& .MuiBadge-badge": { top: 2, right: 4 } }}
        >
          <Notification variant="Bold" />
        </Badge>
      </IconButton>
      <Popper
        sx={{
          boxShadow:
            theme.palette.mode === ThemeMode.DARK
              ? "rgb(222, 222, 222) 0px 9px 32px -38px"
              : "rgba(222, 222, 222, 0.53) 0px 0px 35px 2px",
          borderRadius: 1.5,
          overflow: "hidden",
          border: "1px solid #dedede1a",
        }}
        placement="bottom-end"
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
        popperOptions={{
          modifiers: [
            {
              name: "offset",
              options: {
                offset: [50, 9],
              },
            },
          ],
        }}
      >
        {({ TransitionProps }) => (
          <Transitions
            type="grow"
            position="top-right"
            sx={{ overflow: "hidden" }}
            in={open}
            {...TransitionProps}
          >
            <Paper
              sx={{
                boxShadow: theme.customShadows.z1,
                borderRadius: 1.5,
                width: isLoading ? "400px" : "100%",
                width: 420,
              }}
            >
              <ClickAwayListener onClickAway={handleClose}>
                <MainCard elevation={0} border={false}>
                  <Stack
                    direction="row"
                    alignItems="center"
                    justifyContent="space-between"
                  >
                    <Typography variant="h5">Notifications</Typography>
                    <Box>
                      <Button
                        onClick={(e) => {
                          handleClose(e);
                          navigate("/notification");
                        }}
                      >
                        View all
                      </Button>
                      <Button onClick={handleDeleteAll}>Clear all</Button>
                    </Box>
                  </Stack>
                  <Box
                    sx={{
                      maxHeight: 300,
                      overflowY: "scroll",
                      "&::-webkit-scrollbar": {
                        width: 6,
                      },
                      "&::-webkit-scrollbar-thumb": {
                        backgroundColor: theme.palette.primary.main,
                        borderRadius: 2,
                      },
                      "&::-webkit-scrollbar-track": {
                        backgroundColor: theme.palette.background.paper,
                      },
                    }}
                  >
                    {isLoading ? (
                      <List
                        component="nav"
                        sx={{
                          "& .MuiListItemButton-root": {
                            p: 1.5,
                            my: 1.5,
                            border: `1px solid ${theme.palette.divider}`,
                          },
                        }}
                      >
                        {Array.from(new Array(3)).map((_, index) => (
                          <ListItemButton key={index}>
                            <ListItemAvatar>
                              <Skeleton
                                variant="circular"
                                width={40}
                                height={40}
                              />
                            </ListItemAvatar>
                            <ListItemText
                              primary={
                                <Skeleton
                                  variant="text"
                                  width="60%"
                                  height={20}
                                />
                              }
                              secondary={
                                <Skeleton
                                  variant="text"
                                  width="40%"
                                  height={16}
                                />
                              }
                            />
                            <ListItemSecondaryAction>
                              <Skeleton variant="text" width={40} height={16} />
                              <Skeleton variant="text" width={60} height={16} />
                            </ListItemSecondaryAction>
                          </ListItemButton>
                        ))}
                      </List>
                    ) : notifications?.length === 0 ? (
                      <Typography
                        variant="body2"
                        sx={{ textAlign: "center", mt: 2, padding: 5 }}
                      >
                        Oops! Looks like there are no notifications here.
                      </Typography>
                    ) : (
                      <List
                        component="nav"
                        sx={{
                          "& .MuiListItemButton-root": {
                            p: 1.5,
                            my: 1.5,
                            border: `1px solid ${theme.palette.divider}`,
                            "&:hover": {
                              bgcolor: "#6293ff24",
                              borderColor: theme.palette.primary.light,
                            },
                            "& .MuiListItemSecondaryAction-root": {
                              ...actionSX,
                              position: "relative",
                            },
                          },
                        }}
                      >
                        {notifications?.map((notification, index) => {
                          const type =
                            ListItemButtonTypes[notification.title] || {};
                          const { backgroundColor, hoverColor, icon } = type;
                          const borderColor =
                            avatarBorderColors[notification.title] || "#000";
                          const { time, formattedDate } = formatDate(
                            notification.timestamp
                          );
                          return (
                            <CustomTooltip>
                              <ListItemButton
                                sx={{
                                  position: "relative",
                                  transition: "0.3s",
                                  backgroundColor: `${backgroundColor} !important`,
                                  "&:hover": {
                                    backgroundColor: hoverColor,
                                    ".hover-buttons": {
                                      opacity: 1,
                                      visibility: "visible",
                                      display: "flex",
                                    },
                                  },

                                  ".hover-buttons": {
                                    display: "none",
                                  },
                                }}
                                key={index}
                                onClick={() => {
                                  handleNotificationPage(notification);
                                  setPermissions(notification);
                                }}
                                backgroundColor={backgroundColor}
                                onMouseEnter={() =>
                                  handleMouseEnter(notification)
                                }
                                onMouseLeave={handleMouseLeave}
                              >
                                <ListItemAvatar>
                                  <Avatar
                                    style={{
                                      backgroundColor:
                                        theme.palette.mode === "dark"
                                          ? notification?.title === "Submitted"
                                            ? "#386b3ec4"
                                            : notification?.title === "Approved"
                                            ? "#ffffff2e"
                                            : "#a3c0ff29"
                                          : notification?.title === "Submitted"
                                          ? "#55935247"
                                          : notification?.title === "Approved"
                                          ? "#90d16059"
                                          : "#a3c0ff61",
                                    }}
                                  >
                                    {icon}
                                  </Avatar>
                                </ListItemAvatar>
                                <ListItemText
                                  primary={
                                    <Typography
                                      variant="h6"
                                      sx={{
                                        fontWeight: "medium",
                                        overflow: "hidden",
                                        textOverflow: "ellipsis",
                                        whiteSpace: "nowrap",
                                      }}
                                    >
                                      {notification.title}
                                    </Typography>
                                  }
                                  secondary={
                                    <Typography
                                      variant="body2"
                                      sx={{
                                        color: "text.secondary",
                                        marginRight: "8px",
                                        overflow: "hidden",
                                        textOverflow: "ellipsis",
                                        whiteSpace: "nowrap",
                                      }}
                                    >
                                      {notification.message}
                                    </Typography>
                                  }
                                />
                                <ListItemSecondaryAction>
                                  <Typography
                                    variant="caption"
                                    noWrap
                                    sx={{
                                      fontStyle: "italic",
                                      display: "block",
                                    }}
                                  >
                                    {time}
                                  </Typography>
                                  <Typography
                                    variant="caption"
                                    noWrap
                                    sx={{
                                      fontStyle: "italic",
                                      display: "block",
                                    }}
                                  >
                                    {formattedDate}
                                  </Typography>
                                </ListItemSecondaryAction>
                                {hoveredNotification === notification && (
                                  <IconButton
                                    onClick={() =>
                                      DeleteOneNotification(
                                        notification.uniqueId
                                      )
                                    }
                                    sx={{
                                      opacity: 1,
                                      transition: "opacity 0.3s ease-in-out",
                                      "&:hover": {
                                        backgroundColor: "#ff70432e",
                                      },
                                    }}
                                  >
                                    <Trash size="32" color="#FF8A65" />
                                  </IconButton>
                                )}
                              </ListItemButton>
                            </CustomTooltip>
                          );
                        })}
                      </List>
                    )}
                  </Box>
                </MainCard>
              </ClickAwayListener>
            </Paper>
          </Transitions>
        )}
      </Popper>

      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert
          onClose={handleSnackbarClose}
          severity="error"
          sx={{
            width: "100%",
            bgcolor: "#8B0000", // Dark red color
            color: "#FFFFFF", // White font color
          }}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>

      {tempNotification && (
        <NotificationStyle notification={tempNotification} />
      )}
    </Box>
  );
};

export default withRouter(NotificationPage);
