import { lazy } from "react";
import Loadable from "components/Loadable";
import AuthGuard from "utils/route-guard/AuthGuard";
import MainLayout from "layout/MainLayout";

// Define lazy-loaded components
const Permission = Loadable(lazy(() => import("../Permission")));

// Helper function to create routes
const createRoute = (path, element, children) => ({ path, element, children });

// Define routes using lazy-loaded components
const PermissionRoute = createRoute(
  "/",
  <AuthGuard>
    <MainLayout />
  </AuthGuard>,
  [createRoute("permission", null, [createRoute("table", <Permission />)])]
);

export default PermissionRoute;
