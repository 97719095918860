import React from "react";
import { Box, Card, Grid, Typography } from "@mui/material";
import FolderIcon from "@mui/icons-material/Folder";
import CustomFolderSkeleton from "./CustomFolderSkeleton";
import Error404 from "./reusables/404";

const DynamicFolder = ({
  isLoading = false,
  data = [],
  title = "",
  onClick,
  headingMain = "Oops, looks like there are no data",
  subHeading = "",
}) => {
  return (
    <>
      {isLoading ? (
        <CustomFolderSkeleton length={12} />
      ) : data?.length > 0 ? (
        <Grid container spacing={2}>
          {data?.map((item, index) => (
            <Grid item xs={12} sm={6} md={4} lg={3} xl={2} key={index}>
              <Card
                style={{ width: "100%", height: "100%", cursor: "pointer" }}
              >
                <Box
                  onClick={() => onClick(item)}
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    textAlign: "center",
                    borderRadius: 2,
                    padding: 2,
                    height: "100%",
                    justifyContent: "center",
                    cursor: "pointer",
                    "&:hover": {
                      transform: "scale3d(1.02, 1.02, 1)",
                      transition: "all .10s ease-in-out",
                    },
                  }}
                >
                  <FolderIcon
                    sx={{ fontSize: 81, color: "#5c8ee0", cursor: "pointer" }}
                  />
                  <Typography>{item?.[title] || "-"}</Typography>
                </Box>
              </Card>
            </Grid>
          ))}
        </Grid>
      ) : (
        <Error404 headingMain={headingMain} subHeading={subHeading} />
      )}
    </>
  );
};

export default DynamicFolder;
