import React, { useEffect, useState } from "react";
import {
  Button,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  DialogTitle,
  Divider,
  DialogContent,
  DialogActions,
  Grid,
  FormHelperText,
} from "@mui/material";
import * as url from "./Utils/urlHelper";
import CustomDialog from "components/reusables/CustomDialog";
import { showToast } from "services/utils";
import * as mainUrl from "services/url_helper";
import withRouter from "components/withRouter";

const RecallModal = (props) => {
  const {
    open,
    setOpen,
    version,
    isMultipleDepartment = true,
    isMultipleUser = true,
    ApiServices,
    userData,
    handleGetPermission,
    Constants,
  } = props;

  const [organogramData, setOrganogramData] = useState([]);
  const [selectedDepartment, setSelectedDepartment] = useState([]);
  const [selectedDesignation, setSelectedDesignation] = useState([]);
  const [productName, setProductName] = useState("");
  const [errors, setErrors] = useState({});
  const [productData, setProductData] = useState([]);
  const handleClose = () => {
    setOpen(false);
    setSelectedDepartment([]);
    setProductName("");
  };

  const validateForm = () => {
    let tempErrors = {};
    if (!productName) tempErrors.productName = "Product Name is required";
    if (!selectedDepartment.length)
      tempErrors.selectedDepartment = "Select at least one department";
    if (!selectedDesignation.length)
      tempErrors.selectedDesignation = "Select at least one designation";
    setErrors(tempErrors);
    return Object.keys(tempErrors).length === 0; // Check if any errors exist
  };

  const getAllDepartments = (data) => {
    let result = [];

    const recurse = (nodes) => {
      nodes?.forEach((node) => {
        if (node?.children && node?.children.length > 0) {
          if (node.title != "Managing Director" && node.title != "Director") {
            result.push(node?.designation);
          }
          recurse(node?.children);
        }
      });
    };

    recurse(data);
    return result;
  };

  const filterDepartments = (data, selectedDepartments) => {
    const result = [];

    const recursiveSearch = (items) => {
      for (const item of items) {
        if (selectedDepartments.includes(item.designation)) {
          if (item.children) {
            for (const itm of item.children) {
              const obj = {
                designation: itm.designation,
                userData: itm?.userData,
              };
              result.push(obj);
            }
          }
        } else if (item.children) {
          recursiveSearch(item.children);
        }
      }
    };
    recursiveSearch(data);
    const uniqueArray = [...new Set(result)];
    return uniqueArray;
  };

  const getProducts = async () => {
    try {
      const uri = url.GET_PRODUCTS;
      const reqObj = {
        orgId: userData?.orgId,
      };
      const result = await ApiServices.callServicePostWithBodyData(uri, reqObj);
      if (result?.meta?.success) {
        setProductData(result?.data);
      }
    } catch (error) {
      console.error("ERROR TO GET PRODUCTS", error);
    }
  };

  const getHeadOfAllDepartment = async () => {
    let reqObj = {
      isHead: true,
      orgId: userData?.orgId,
      version: version ?? "v00",
    };
    let uri = mainUrl.GET_HEAD_DATA_BY_ORGANOGRAM;
    let result = await ApiServices.callServicePostWithBodyData(uri, reqObj);
    if (result?.response) {
      setOrganogramData(result?.result?.data?.organogramData);
    }
  };

  useEffect(() => {
    if (open) {
      getHeadOfAllDepartment();
      getProducts();
    }
  }, [open]);

  const sendNotification = async () => {
    try {
      const reqObj = {
        dataArray: [
          {
            recipients: selectedDesignation?.map((val) => ({
              userId: val?.userData?.userId,
            })),
            msg: "Recall Notified",
            status: "Recall Notified",
            location: `/advisory-recall/recall-list/${new Date().getFullYear()}`,
          },
        ],
      };
      const URL = mainUrl.GET_NOTIFICATIONS_QAHEAD;
      const result = await ApiServices.callServicePostWithBodyData(URL, reqObj);
    } catch (error) {
      console.error("GET_NOTIFICATIONS_QAHEAD API error", error);
    }
  };

  const handleClearState = () => {
    setSelectedDepartment([]);
    setSelectedDesignation([]);
    setProductName("");
    setOpen(false);
  };
  const handleSubmit = async () => {
    if (!validateForm()) return;
    try {
      const reqObj = {
        documentName: "Advisory-Recall",
        userList: selectedDesignation?.map((val) => ({
          userId: val?.userData?.userId,
        })),
        orgId: userData?.orgId,
        product: productName,
        module: "recall",
      };

      const uri = url.POST_PRO_FORMAT_PRODUCT_RECALL_REGISTER;
      const result = await ApiServices.callServicePostWithBodyData(uri, reqObj);

      if (result?.meta?.success == true) {
        sendNotification();
        showToast("Product recall registered successfully!", true);
        handleClearState();
      }
    } catch (error) {
      console.error("ERROR TO SUBMIT ", error);
    }
  };

  const handleInputChange = (field, value) => {
    if (field === "productName") setProductName(value);
    if (field === "selectedDepartment") setSelectedDepartment(value);
    if (field === "selectedDesignation") setSelectedDesignation(value);

    // Clear the error for the specific field
    setErrors((prevErrors) => ({ ...prevErrors, [field]: "" }));
  };

  return (
    <CustomDialog open={open} maxWidth="sm">
      <DialogTitle>
        Select Product Name, Departments and Designation to notify users
      </DialogTitle>
      <Divider />
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <FormControl error={!!errors.productName} fullWidth>
              <InputLabel>Product Name</InputLabel>
              <Select
                value={productName || ""}
                onChange={(e) =>
                  handleInputChange("productName", e?.target?.value)
                }
              >
                {productData?.map((option, index) => {
                  return (
                    <MenuItem value={option?._id} key={index}>
                      {option?.productName}
                    </MenuItem>
                  );
                })}
              </Select>
              {!!errors?.productName && (
                <FormHelperText error>{errors?.productName}</FormHelperText>
              )}
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <FormControl fullWidth error={!!errors.selectedDepartment}>
              <InputLabel>Department</InputLabel>
              <Select
                multiple={isMultipleDepartment}
                value={selectedDepartment}
                onChange={(e) =>
                  handleInputChange("selectedDepartment", e.target.value)
                }
                displayEmpty
                renderValue={(selected) =>
                  isMultipleDepartment
                    ? selected?.length === 0
                      ? ""
                      : selected?.join(", ")
                    : selected
                }
              >
                {getAllDepartments(organogramData)?.map((dept, index) => (
                  <MenuItem key={index} value={dept}>
                    {dept}
                  </MenuItem>
                ))}
              </Select>
              <p style={{ color: "red", marginTop: "4px" }}>
                {errors.selectedDepartment}
              </p>
            </FormControl>
          </Grid>
          {selectedDepartment?.length > 0 && (
            <Grid item xs={12}>
              <FormControl fullWidth error={!!errors.selectedDesignation}>
                <InputLabel>Select Designation</InputLabel>
                <Select
                  multiple={isMultipleUser}
                  value={selectedDesignation || []}
                  onChange={(e) =>
                    handleInputChange("selectedDesignation", e.target.value)
                  }
                  displayEmpty
                  renderValue={(selected) =>
                    selected?.map((des) => des?.userData?.name).join(", ")
                  }
                >
                  <MenuItem disabled value="">
                    <em>Select</em>
                  </MenuItem>
                  {filterDepartments(organogramData, selectedDepartment)?.map(
                    (dept, index) => (
                      <MenuItem key={index} value={dept}>
                        {dept?.designation} - {dept?.userData?.name}
                      </MenuItem>
                    )
                  )}
                </Select>
                <p style={{ color: "red", marginTop: "4px" }}>
                  {errors.selectedDesignation}
                </p>
              </FormControl>
            </Grid>
          )}
        </Grid>
      </DialogContent>
      <Divider />
      <DialogActions sx={{ p: 2.5 }}>
        <Button variant="contained" color="error" onClick={handleClose}>
          Close
        </Button>
        {handleGetPermission("Recall List", Constants?.KEY_RBAC_UPDATE) && (
          <Button variant="contained" color="success" onClick={handleSubmit}>
            Submit
          </Button>
        )}
      </DialogActions>
    </CustomDialog>
  );
};
export default withRouter(RecallModal);
