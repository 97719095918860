import { lazy } from "react";

// project-imports
import Loadable from "components/Loadable";
import AuthGuard from "utils/route-guard/AuthGuard";
import MainLayout from "layout/MainLayout";

// render - pages
const InstallationChecklist = Loadable(
  lazy(() => import("../InstallationChecklist"))
);
const List = Loadable(lazy(() => import("../List")));
const YearList = Loadable(lazy(() => import("../YearList")));
const NotifyForInstallationHead = Loadable(
  lazy(() => import("../NotifyForInstallationHead"))
);
const InstallationChildFormats = Loadable(
  lazy(() => import("../InstallationChildFormats"))
);
const InstallationFormat = Loadable(
  lazy(() => import("../InstallationFormat"))
);
const InstallationReport = Loadable(
  lazy(() => import("../InstallationReport"))
);

// Helper function to create routes
const createRoute = (path, element, children) => ({ path, element, children });

// ==============================|| AUTH ROUTES ||============================== //

const InstallationRoutes = createRoute(
  "/",
  <AuthGuard>
    <MainLayout />
  </AuthGuard>,
  [
    createRoute("installation", null, [
      createRoute("installation-checklist", <InstallationChecklist />),
      createRoute(
        "notification-for-installation-head",
        <NotifyForInstallationHead />
      ),
      createRoute("installation-format", <InstallationFormat />),
      createRoute("installation-childFormats", <InstallationChildFormats />),
      createRoute("installation-report", <InstallationReport />),
      createRoute("list", <List />),
      createRoute("year-list", <YearList />),
    ]),
  ]
);

export default InstallationRoutes;
