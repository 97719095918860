const baseUrl = "/preventive-maintenance";

// preventive-maintenance
export const POST_PREVENTIVE_MAINTENANCE = `${baseUrl}/`;
export const GET_BY_DOCUMENT_NAME = `${baseUrl}/get-by-document-name`;
export const UPDATE_SPECIFIC = `${baseUrl}/update-specific`;
export const UPDATE_PREVENTIVE_MAINTANACE = `${baseUrl}/update-create-report`;
export const POST_GET_MACHINE_DATA = `${baseUrl}/get-machine-data`;
export const POST_PREVENTIVE_MAINTANACE = `${baseUrl}/create-report`;
export const GET_ALL_PREVENTIVE_MAINTANANCE_DATA = `${baseUrl}/get-all`;
export const GET_DYNAMIC_MONITORING_MEASUREMENT_EQUIPMENT = `${baseUrl}/get-dynamic`;
export const DOWNLOAD = `${baseUrl}/download`;

export const DOWNLOAD_BREAK_DOWN = `${baseUrl}/download-break-down`;
export const GET_YEAR = `${baseUrl}/get-year`;
