import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  TextField,
  DialogActions,
  Grid,
  Skeleton,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import MainCard from "components/MainCard";
import { downloadFormat, getTitle, showToast } from "services/utils";
import * as url from "./utils/urlHelper";
import { CustomHooks } from "hooks/CustomHooks";
import withRouter from "components/withRouter";
import SkeletonTable from "components/SkeletonTable";

const ExternalListOfDoc = (props) => {
  const {
      userData,
      ApiServices,
      handleGetPermission,
      Constants,
      onChangeDownloadState,
    } = props,
    { navigate } = props?.router;
  const { documentId, procedureId } = CustomHooks.useUrlParams();
  const [externalOriginDocData, setExternalOriginDocData] = useState({});
  const [isLoading, setIsLoading] = useState(true);

  const handleInputChange = (e, index) => {
    const { name, value } = e.target;
    setExternalOriginDocData((prevState) => {
      if (prevState && Array.isArray(prevState.document)) {
        const updatedDocument = prevState.document.map((doc, i) =>
          i === index ? { ...doc, [name]: value } : doc
        );
        return {
          ...prevState,
          document: updatedDocument,
        };
      }
      return prevState;
    });
  };

  const handleDeleteDocument = (index) => {
    if (externalOriginDocData.document.length > 1) {
      const updatedDocuments = externalOriginDocData.document.filter(
        (doc, i) => i !== index
      );
      const updatedDocumentsWithSrNo = updatedDocuments.map((doc, i) => ({
        srNo: i + 1,
        ...doc,
      }));
      setExternalOriginDocData((prevState) => {
        return {
          ...prevState,
          document: updatedDocumentsWithSrNo,
        };
      });
    }
  };

  const handleAddRowBelow = (index) => {
    const newDocument = {
      srNo: externalOriginDocData.document.length + 1,
      externalOriginDocs: "",
    };
    const updatedDocuments = [
      ...externalOriginDocData.document.slice(0, index + 1),
      newDocument,
      ...externalOriginDocData.document.slice(index + 1),
    ];
    const updatedDocumentsWithSrNo = updatedDocuments.map((doc, i) => ({
      srNo: i + 1,
      ...doc,
    }));
    setExternalOriginDocData((prevState) => {
      return {
        ...prevState,

        document: updatedDocumentsWithSrNo,
      };
    });
  };

  const handleDownload = async () => {
    let data =
      externalOriginDocData?.document?.map((item) => item.externalOriginDocs) ||
      [];
    const hasEmptyDocs = data.some((doc) => !doc);
    if (hasEmptyDocs) {
      showToast("Please fill in the data");
      return true;
    }

    let reqObj = {
      formatId: documentId,
    };
    let uri = url.DOWNLOAD_COD;
    await downloadFormat(uri, reqObj, onChangeDownloadState);
  };

  const updateDocument = async () => {
    try {
      let data =
        externalOriginDocData?.document?.map(
          (item) => item.externalOriginDocs
        ) || [];
      const hasEmptyDocs = data.some((doc) => !doc);
      if (hasEmptyDocs) {
        showToast("Please fill in the external Origin Doc Data");
        return true;
      }

      let reqObj = {
        document: externalOriginDocData.document.map((doc, index) => ({
          ...doc,
          srNo: index + 1,
        })),
      };
      let uri = url.GET_OR_UPDATE_EXTERNAL_LIST + documentId;
      let result = await ApiServices.callServicePutWithBodyData(uri, reqObj);

      showToast(result);
      if (result?.meta?.success) {
        getDocument();
      }
    } catch (error) {
      console.error("GET_CHILD_FORMATS_DATA_BY_FORMATNUMBER ERROR", error);
    }
  };

  const getDocument = async () => {
    try {
      let uri = url.GET_OR_UPDATE_EXTERNAL_LIST + `/${documentId}`;
      let result = await ApiServices.callServiceGet(uri);

      if (result?.meta?.success) {
        let documents = result?.data?.document;
        if (!documents || documents.length === 0) {
          documents = [
            { externalOriginDocs: "", srNo: 1 },
            { externalOriginDocs: "", srNo: 2 },
          ];
        } else {
          documents = documents.map((doc, index) => ({
            externalOriginDocs: doc.externalOriginDocs || "",
            srNo: doc.srNo || index + 1,
          }));
        }
        setExternalOriginDocData({
          ...result.data,
          document: documents,
        });
      }
    } catch (error) {
      console.error("GET_CHILD_FORMATS_DATA_BY_FORMATNUMBER ERROR", error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getDocument();
  }, [procedureId, documentId]);

  return (
    <MainCard
      title={getTitle("External Origin Docs")}
      secondary={
        isLoading ? (
          <Skeleton
            variant="rectangular"
            height="30px"
            width="100px"
            sx={{
              borderRadius: "12px",
            }}
          />
        ) : (
          !userData?.externalUser &&
          handleGetPermission(
            "External List of Documnets - COD",
            Constants.KEY_RBAC_DOWNLOAD
          ) && (
            <Button
              variant="contained"
              color="success"
              onClick={handleDownload}
            >
              Download
            </Button>
          )
        )
      }
    >
      <Grid container spacing={2}>
        {isLoading ? (
          <Grid item xs={12}>
            <SkeletonTable />
          </Grid>
        ) : (
          <>
            <Grid item xs={12}>
              <TableContainer component={Paper}>
                <Table>
                  <TableHead sx={{ fontSize: "1.25rem" }}>
                    <TableRow>
                      <TableCell
                        align="left"
                        style={{ width: "10%", fontSize: "0.85rem" }}
                      >
                        Sr. No.
                      </TableCell>
                      <TableCell
                        align="center"
                        style={{ width: "75%", fontSize: "0.85rem" }}
                      >
                        Title
                      </TableCell>
                      <TableCell
                        align="left"
                        style={{ width: "15%", fontSize: "0.85rem" }}
                      >
                        Actions
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {externalOriginDocData?.document?.map((doc, index) => (
                      <TableRow key={index}>
                        <TableCell sx={{ fontSize: "1rem" }}>
                          {index + 1}
                        </TableCell>
                        <TableCell sx={{ textAlign: "center" }}>
                          <TextField
                            fullWidth
                            name="externalOriginDocs"
                            value={doc?.externalOriginDocs}
                            onChange={(e) => handleInputChange(e, index)}
                            sx={{
                              "& .Mui-disabled": {
                                WebkitTextFillColor: "black",
                              },
                            }}
                            disabled={userData?.externalUser ? true : false}
                          />
                        </TableCell>
                        <TableCell>
                          <IconButton
                            color="secondary"
                            onClick={() => handleDeleteDocument(index)}
                            disabled={userData?.externalUser ? true : false}
                          >
                            <DeleteIcon sx={{ color: "red" }} />
                          </IconButton>
                          <IconButton
                            color="primary"
                            onClick={() => handleAddRowBelow(index)}
                            disabled={userData?.externalUser ? true : false}
                          >
                            <AddIcon />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
            {!userData?.externalUser && (
              <Grid
                item
                xs={12}
                display="flex"
                justifyContent="flex-end"
                alignItems="center"
                gap=".5rem"
              >
                <Button
                  variant="contained"
                  color="error"
                  onClick={() => navigate(-1)}
                >
                  Cancel
                </Button>
                {handleGetPermission(
                  "External List of Documnets - COD",
                  Constants.KEY_RBAC_CREATE
                ) && (
                  <Button
                    type="submit"
                    variant="contained"
                    onClick={() => updateDocument()}
                  >
                    Save
                  </Button>
                )}
              </Grid>
            )}
          </>
        )}
      </Grid>
    </MainCard>
  );
};

export default withRouter(ExternalListOfDoc);
