import React, { useState, useEffect } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Box,
  Select,
  Button,
  Grid,
  MenuItem,
} from "@mui/material";
import { CustomHooks } from "hooks/CustomHooks";
import { ApiServices } from "services/apiServices";
import * as url from "./utils/urlHelper";
import { downloadFormat, getTitle, showToast } from "services/utils";
import MainCard from "components/MainCard";
import withRouter from "components/withRouter";

const MasterListOfQualityRecords = (props) => {
  const { onChangeDownloadState, handleGetPermission, Constants } = props;
  const { documentId, procedureId } = CustomHooks.useUrlParams();
  const [rows, setRows] = useState([]);

  const getDocument = async () => {
    try {
      const reqObj = {
        documentName: "Master List of Quality Records",
        childformatId: documentId,
      };
      let uri = url.GET_MASTER_LIST;
      let result = await ApiServices.callServicePostWithBodyData(uri, reqObj);
      if (result?.meta?.success) {
        const documents = result?.data?.document?.map((doc, index) => ({
          ...doc,
          retentionPeriod: doc.hasOwnProperty("retentionPeriod")
            ? doc.retentionPeriod
            : "",
        }));

        setRows(documents);
      }
    } catch (error) {
      console.error("GET_MASTER_LIST ERROR", error);
    }
  };
  const handleSave = async () => {
    try {
      let reqObj = {
        document: rows,
      };
      let uri = url.UPDATE_MASTER_LIST + documentId;
      let result = await ApiServices.callServicePutWithBodyData(uri, reqObj);
      showToast(result);
      if (result?.meta?.success) {
        getDocument();
      }
    } catch (error) {
      console.error("UPDATE_MASTER_LIST ERROR", error);
    }
  };

  const handleRetentionPeriodChange = (index, value) => {
    const updatedRows = [...rows];
    updatedRows[index].retentionPeriod = value;
    setRows(updatedRows);
  };

  const handleDownload = async () => {
    let reqObj = {
      formatId: documentId,
    };
    let uri = url.DOWNLOAD_COR;
    await downloadFormat(uri, reqObj, onChangeDownloadState);
  };

  useEffect(() => {
    getDocument();
  }, [documentId, procedureId]);

  return (
    <MainCard
      title={getTitle("Master List of Quality Records")}
      secondary={
        handleGetPermission(
          "Master List of Records - COR",
          Constants.KEY_RBAC_DOWNLOAD
        ) && (
          <Button
            variant="contained"
            color="success"
            onClick={() => handleDownload()}
          >
            Download
          </Button>
        )
      }
    >
      <Grid item xs={12}>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell sx={{ textAlign: "center" }}>Sr. No.</TableCell>
                <TableCell sx={{ textAlign: "center" }}>Format Name</TableCell>
                <TableCell sx={{ textAlign: "center" }}>
                  Rentention Period
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rows?.length > 0 ? (
                rows?.map((row, index) => (
                  <TableRow key={index}>
                    <TableCell>{index + 1}</TableCell>
                    <TableCell>{row?.documentName}</TableCell>
                    <TableCell>
                      <Select
                        value={row?.retentionPeriod}
                        onChange={(e) =>
                          handleRetentionPeriodChange(index, e?.target?.value)
                        }
                        sx={{
                          "& .Mui-disabled": {
                            WebkitTextFillColor: "black",
                          },
                        }}
                        displayEmpty
                        fullWidth
                      >
                        <MenuItem value="">
                          <em>Select Frequency</em>
                        </MenuItem>
                        <MenuItem value="0.5">6 months</MenuItem>
                        <MenuItem value="1">1 year</MenuItem>
                        <MenuItem value="2">2 years</MenuItem>
                        <MenuItem value="3">3 years</MenuItem>
                        <MenuItem value="4">4 years</MenuItem>
                        <MenuItem value="5">5 years</MenuItem>
                      </Select>
                    </TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan={3} sx={{ textAlign: "center" }}>
                    No Data Found
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <Box sx={{ display: "flex", justifyContent: "flex-end", mt: 3 }}>
          {handleGetPermission(
            "Master List of Records - COR",
            Constants.KEY_RBAC_UPDATE
          ) && (
            <Button onClick={handleSave} variant="contained" color="primary">
              Save
            </Button>
          )}
        </Box>
      </Grid>
    </MainCard>
  );
};

export default withRouter(MasterListOfQualityRecords);
