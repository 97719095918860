import {
  Box,
  Button,
  Card,
  CardContent,
  Chip,
  ClickAwayListener,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Paper,
  Popper,
  Select,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
  useMediaQuery,
  List,
  ListSubheader,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  FormHelperText,
} from "@mui/material";
import { DesktopDatePicker } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import CustomDialog from "components/reusables/CustomDialog";
import { Category } from "iconsax-react";
import { useEffect, useRef, useState } from "react";
import {
  camelCaseToString,
  getItem,
  getUserData,
  sendNotification,
  showToast,
  transformUserDesignationObject,
  getAllUserIds,
  getAllUserIdsForDepartment,
  dispatchFunction,
  getYearFromDate,
  isNotNullOrUndefined,
  setItem,
  checkUserPermission,
} from "services/utils";
import { CheckCircleOutline, CloudUpload } from "@mui/icons-material";
import ContentCopyOutlinedIcon from "@mui/icons-material/ContentCopyOutlined";
import copy from "copy-to-clipboard";
import EmailSelector from "components/EmailSelector";
import MainCard from "components/MainCard";
import { CustomHooks } from "hooks/CustomHooks";
import Transitions from "components/@extended/Transitions";
import Waves from "assets/images/waves.svg";
import Dot from "components/@extended/Dot";
import { ADD_DATA } from "pages/CRPD/utils/urlHelpers";
import { getUsersDataByDepartment } from "services/organogramServices";
import DynamicNotification from "components/DynamicNotification";
import FeedbackForm from "pages/Feedback/FeedbackForm";
import { ADD_FEEDBACK_DATA } from "pages/Feedback/utils/urlHelper";
import { CREATE_INTERNAL_COMMUNICATION } from "pages/Internal-Communication/utils/urlHelper";
import RecallModal from "pages/Advisory-Recall/RecallModal";
import withRouter from "components/withRouter";
import {
  GET_ALL_CREATED_PROCEDURE_BY_ID,
  GET_FORMAT_LIST,
} from "services/url_helper";

const MegaMenuSection = (props) => {
  const isSmallScreen = useMediaQuery("(min-width:1000px)");
  const {
      theme,
      userData,
      handleGetPermission,
      Constants,
      changeNoteData: changeNoteDataFromLocal,
      userRole,
    } = props,
    { navigate } = props?.router,
    { ThemeMode, DRAWER_WIDTH } = props?.config;
  const [errors, setErrors] = useState();
  const anchorRef = useRef(null);
  const [open, setOpen] = useState(false);
  const standardOption = ["ISO13485"];
  const feedbackOptions = ["Internal", "External"];
  const internalCircularOptions = ["All Users", "Department", "Select Users"];
  const customerFeedbackOptions = [
    "Generate-Link",
    "Fill-Customer-Feedback",
    "Generate-Link-Invite",
  ];
  const [generateOrderData, setGenerateOrderData] = useState({
    email: "",
    mobile: "",
  });
  const [generateOrderDataError, setGenerateOrderDataError] = useState(false);
  const [selectedDepartments, setSelectedDepartments] = useState([]);
  const [changeNoteEdit, setChangeNoteEdit] = useState(false);
  const [selectedRole, setSelectedRole] = useState("");
  const changeNoteDataFromLS = changeNoteDataFromLocal;
  const [changeNoteDataFromApi, setChangeNoteDataFromApi] = useState();
  const [openRecallModal, setOpenRecallModal] = useState(false);
  const [userList, setUserList] = useState([]);
  const [allRoles, setAllRoles] = useState();
  const [designationUserList, setDesignationUserList] = useState([]);
  const [masterData, setMasterData] = useState({});
  const [documents, setDocuments] = useState([]);
  const [selectedModal, setSelectedModal] = useState("");
  const [docTypeOption, setDocTypeOption] = useState([]);
  const [changeNoteData, setChangeNoteData] = useState({
    standard: "ISO13485",
    documentType: [],
    selectedDocuments: [],
    changeNoteNo: "",
    dateOfRequest: new Date(),
    documentNo: "",
    revisionNo: "",
    revisionDate: "",
    assignedUserId: [],
    reasonforChange: [""],
    requesterDetails: {
      requesterName: userData?.name,
      userId: userData?._id,
      requesterDesignation: userRole,
      signatureRequest: "",
    },
  });
  const formikRef = useRef(null);
  const linkRef = useRef(null);
  const [copied, setCopied] = useState(false);
  const [feedbackData, setFeedbackData] = useState({
    type: "Internal",
    subType: "Generate-Link",
    message: "",
    selectedOption: "copyLink",
    userEmails: [],
    customerData: {
      name: "",
      email: "",
      address: "",
      mobile: "",
    },
    uploadedFile: null,
  });
  const [internalCircular, setInternalCircular] = useState({
    circularInitiator: { name: userData?.name, _id: userData?._id },
    department: userData?.role,
    date: new Date().toISOString().split("T")[0],
    selectType: "All Users",
    selectDepartment: "",
    communicatedTo: [],
    description: "",
  });
  const [internalCircularErrors, setInternalCircularErrors] = useState({});
  const [feedBackResponce, setFeedBackResponce] = useState(null);
  const [error, setError] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [fileError, setFileError] = useState("");
  const [agentErrors, setAgentErrors] = useState({});
  const [agentData, setAgentData] = useState({
    name: "",
    address: "",
  });
  const [isOpenNotification, setIsOpenNotification] = useState(false);
  const { roleData, roleOptions, getAllRole } = CustomHooks.useGetAllRole();
  const [filterDataBase, setFilterDataBase] = useState([]);

  const getChangeNoteById = async (changeNoteId) => {
    if (changeNoteId?.length > 0 && changeNoteId !== null) {
      try {
        // setSelectedModal("changeNote")
        let uri = props?.commonUrl?.GET_CHANGE_NOTE_BY_ID + changeNoteId;
        let result = await props?.ApiServices.callServiceGet(uri);
        if (result?.result?.type === "success") {
          handleChangeNoteModal(true);
          const changeNoteDataFromApi = result?.result?.data?.changeNoteData;

          setChangeNoteDataFromApi(changeNoteDataFromApi);
          setChangeNoteData({
            standard: changeNoteDataFromApi?.standard || "ISO13485",
            documentType: changeNoteDataFromApi?.documentType || [],
            selectedDocuments: changeNoteDataFromApi?.selectedDocuments || [],
            changeNoteNo: changeNoteDataFromApi?.changeNoteNo || "",
            dateOfRequest:
              new Date(changeNoteDataFromApi?.dateOfRequest) || new Date(),
            documentNo: changeNoteDataFromApi?.documentNo || "",
            revisionNo: changeNoteDataFromApi?.revisionNo || "",
            revisionDate: changeNoteDataFromApi?.revisionDate || "",
            assignedUserId: changeNoteDataFromApi?.assignedUserId || [],
            reasonforChange: changeNoteDataFromApi?.reasonforChange || [""],
            requesterDetails: {
              requesterName:
                changeNoteDataFromApi?.requesterDetails?.requesterName ||
                userData?.name,
              userId:
                changeNoteDataFromApi?.requesterDetails?.userId ||
                userData?._id,
              requesterDesignation:
                changeNoteDataFromApi?.requesterDetails?.requesterDesignation ||
                "",
              signatureRequest:
                changeNoteDataFromApi?.requesterDetails?.signatureRequest || "",
            },
          });
          setSelectedRole(changeNoteDataFromApi?.changeNoteData);
          setChangeNoteEdit(true);
          if (
            result?.result?.data?.changeNoteData?.currentStatus ===
              "Document Approval" ||
            result?.result?.data?.changeNoteData?.currentStatus ===
              "Change request note approved"
          ) {
            localStorage.removeItem(Constants.CHANGENOTEDATA);
          } else {
            let changeNoteObj = result?.result?.data?.changeNoteData;
            changeNoteObj.changeNoteId = result?.result?.data?._id;
            setItem(Constants.CHANGENOTEDATA, JSON.stringify(changeNoteObj));
          }
        }
      } catch (error) {
        console.error("GET_CHANGE_NOTE_BY_ID API", error);
      }
    }
  };

  const handleChangeNoteEdit = async () => {
    const headUser =
      selectedRole === "MR"
        ? await getUsersDataByDepartment("MR", true, "MR")
        : await getUsersDataByDepartment(selectedRole, true, "topManagement");

    const currentDate = new Date().toISOString(); // Get current date in ISO format
    changeNoteData.currentStatus = "Change Request Note Modified";
    const updatedChangeNoteData = {
      ...changeNoteData, // Spread all the existing properties of changeNoteData
      status: {
        ...changeNoteDataFromApi.status, // Spread the status from changeNoteDataFromApi
        changNoteModified: {
          // Add new object changNoteModified
          lastUpdated: currentDate,
          createdAt: changeNoteData?.dateOfRequest,
          responsiblePerson: userData?._id,
          responsiblePersonName: userData?.name,
        },
      },
    };

    let reqObj = {
      _id: changeNoteDataFromLS?.changeNoteId,
      changeNoteData: updatedChangeNoteData,
    };
    let uri = props?.commonUrl?.CREATE_DOC_CHANGE_NOTE;
    let result = await props?.ApiServices.callServicePutWithBodyData(
      uri,
      reqObj
    );
    if (result?.result?.type === "success") {
      // showToast(result);
      window.dispatchEvent(new Event("changeNoteUpdated"));
      setSelectedModal("");
      let notificationObj = {
        dataArray: [
          {
            recipients: headUser?.map((user) => ({ userId: user.userId })),
            msg: "Please approve or reject change note request",
            status: "Approval request",
            location: `changeNoteHistory`,
          },
        ],
      };

      await sendNotification(notificationObj);
    }
  };
  const validateFields = () => {
    const newErrors = {};

    if (!changeNoteData?.standard) {
      newErrors.standard = "Standard is required.";
    }

    if (!changeNoteData?.documentType?.length) {
      newErrors.documentType = "Document type is required.";
    }

    if (!changeNoteData?.selectedDocuments?.length) {
      newErrors.selectedDocuments = "Please select at least one document.";
    }

    if (!changeNoteData?.reasonforChange) {
      newErrors.reasonforChange = "Reason for change is required.";
    }

    if (!selectedRole) {
      newErrors.selectedRole = "Please notify someone.";
    }

    return newErrors;
  };

  const handleToggle = () => {
    if (!open) {
      getAllDocData();
      filterDataCallback();
      getAllRole();
      getAllUserList();
    }
    setOpen((prevOpen) => !prevOpen);
  };
  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
  };

  const handleChangeNoteModal = (editData = false) => {
    let data = getItem(Constants.CHANGENOTEDATA);
    if (data) {
      setChangeNoteData(data);
    }
    setSelectedModal((prevValue) =>
      prevValue === "changeNote" ? "" : "changeNote"
    );
    if (Object.keys(masterData)?.length > 0) {
      let array = Object.keys(masterData)?.map((item) =>
        item?.replace(/([A-Z])/g, " $1").replace(/^./, function (str) {
          return str.toUpperCase();
        })
      );
      setDocTypeOption(array);
    }
    if (editData === false) {
      setChangeNoteData({
        standard: "ISO13485",
        documentType: [],
        selectedDocuments: [],
        changeNoteNo: "",
        dateOfRequest: new Date(),
        documentNo: "",
        revisionNo: "",
        revisionDate: "",
        // proposedforChange: [""],
        reasonforChange: [""],
        requesterDetails: {
          requesterName: userData?.name,
          userId: userData?._id,
          requesterDesignation: userRole,
          signatureRequest: "",
        },
      });
    }
  };

  const handleFeedbackModal = (e) => {
    setSelectedModal((prevValue) =>
      prevValue === "feedback" ? "" : "feedback"
    );
    setFeedbackData({
      type: "Internal",
      subType: "Generate-Link",
      message: "",
      selectedOption: "copyLink",
      userEmails: [],
      customerData: {
        name: "",
        email: "",
        address: "",
        mobile: "",
      },
      uploadedFile: null,
    });
    setFeedBackResponce(null);
    setCopied(false);
    setError(false);
  };

  const handleInternalCircularModal = () => {
    setSelectedModal((prevValue) =>
      prevValue === "internalCircular" ? "" : "internalCircular"
    );
    setInternalCircular({
      circularInitiator: { name: userData?.name, _id: userData?._id },
      department: userData?.role,
      date: new Date().toISOString().split("T")[0],
      selectType: "All Users",
      selectDepartment: "",
      communicatedTo: [],
      description: "",
    });
  };

  const validateEmail = (value) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!value) {
      return "Email is required";
    } else if (!emailRegex.test(value)) {
      return "Invalid email address";
    }
    return "";
  };

  const handleGenerateOrderChange = (e) => {
    const { name, value } = e?.target;
    setGenerateOrderData((prevState) => ({
      ...prevState,
      [name]: value,
    }));

    if (name === "email") {
      if (value !== "") {
        setGenerateOrderDataError(validateEmail(value));
      } else {
        setGenerateOrderDataError(true);
      }
    }
  };

  const handleSubmitOrder = async () => {
    if (generateOrderData?.email?.length === 0) {
      setGenerateOrderDataError(true);
      return;
    }
    try {
      const reqObj = {
        documentName: "Customer Form",
        isFormat: true,
        metaInfo: [
          {
            type: "Phase-0",
            createdByUser: userData?._id,
            customerData: generateOrderData,
            document: null,
            status: "initiated",
            senderUsers: [{ userId: userData?._id }],
            preparedBy: getUserData(userData),
            createdAt: new Date().toISOString(),
            notificationObj: {
              dataArray: [
                {
                  recipients: { userId: userData?._id },
                  msg: "Customer has fill order form. Please Check It.",
                  status: "New Order Request Has been arrived",
                  location: `customer-related-process-and-distribution/order-review/`,
                },
              ],
            },
          },
        ],
        orderStatus: "Pending",
        status: "initiated",
        history: [
          {
            documentName: "Customer Order Link Generated",
            type: "initiated",
            time: new Date().toISOString(),
            userData: getUserData(userData),
          },
        ],
        prefixType: "FormatPrefixes",
      };

      const result = await props?.ApiServices.callServicePostWithBodyData(
        ADD_DATA,
        reqObj
      );
      if (result?.meta?.success) {
        showToast(result);
        setGenerateOrderDataError(false);
        setGenerateOrderData({ email: "", mobile: "" });
        handleGenerateOrderModal();
      } else {
        showToast(result);
      }
    } catch (error) {
      console.error("Error saving data:", error);
    }
  };

  const handleGenerateOrderModal = () => {
    setSelectedModal((prevValue) =>
      prevValue === "generateOrderForm" ? "" : "generateOrderForm"
    );
    setGenerateOrderData({
      email: "",
      mobile: "",
    });
  };

  const handleEmailsChange = (emails) => {
    setFeedbackData((prev) => ({
      ...prev,
      userEmails: emails,
    }));
    setEmailError(emails?.length === 0);
  };

  const filterDataCallback = async () => {
    let uri = props?.commonUrl?.GET_MANUAL_BY_ID;
    let result = await props?.ApiServices.callServiceGet(uri);

    if (result?.result?.type === "success") {
      const manualData = result?.result?.data || [];
      const procedureData = await getProcedureList();
      const formatDatas = await getFormatList();
      const newProcedureData = mapDocuments(procedureData);
      if (newProcedureData || formatDatas) {
        const mergedData = [...manualData, ...newProcedureData, ...formatDatas];

        setFilterDataBase(mergedData);
      }
    }
  };
  const mapDocuments = (documents) => {
    return documents?.map((doc) => {
      const { documentName, documentNumber, versionData } = doc;
      let isOriginal;
      if (isNotNullOrUndefined(versionData)) {
        isOriginal = Object.values(versionData)?.some(
          (version) => version?.documentState === "original"
        );
      }
      const documentState = isOriginal ? "original" : "Draft";
      return {
        documentName,
        documentNumber,
        documentState,
      };
    });
  };

  // getProcedureList remains the same
  const getProcedureList = async () => {
    const reqObj = {
      orgId: userData?.orgId,
    };
    let uri = GET_ALL_CREATED_PROCEDURE_BY_ID;
    let result = await props?.ApiServices.callServicePostWithBodyData(
      uri,
      reqObj
    );
    if (result?.result?.type === "success") {
      return result?.result?.data?.procedures; // Return procedure data
    }
    return null; // Return null if API call fails
  };

  const getFormatList = async () => {
    let uri = GET_FORMAT_LIST;
    let result = await props?.ApiServices.callServiceGet(uri);
    if (result?.response) {
      return result?.result; // Return procedure data
    }
    return null; // Return null if API call fails
  };

  const getAllDocData = async () => {
    try {
      let reqObj = {
        orgId: userData?.orgId,
        standardName: "ISO13485",
      };
      let uri = props?.commonUrl?.CREATE_MASTER_TABLE;
      let result = await props?.ApiServices.callServicePostWithBodyData(
        uri,
        reqObj
      );
      if (result?.result?.type === "success") {
        setMasterData(result?.result?.data?.masterTableData);
        if (Object.keys(result?.result?.data?.masterTableData)?.length > 0) {
          let array = Object.keys(result?.result?.data?.masterTableData)?.map(
            (item) =>
              item?.replace(/([A-Z])/g, " $1").replace(/^./, function (str) {
                return str.toUpperCase();
              })
          );
          setDocTypeOption(array);
        } else {
          setDocTypeOption([]);
        }
      }
    } catch (error) {
      console.error("CREATE_MASTER_TABLE ERROR", error);
    }
  };

  const getDocumentsByType = (types) => {
    let result = [];
    types?.forEach((type) => {
      let string = type
        .replace(/(?:^\w|[A-Z]|\b\w)/g, function (word, index) {
          return index === 0 ? word.toLowerCase() : word.toUpperCase();
        })
        .replace(/\s+/g, "");
      if (masterData?.hasOwnProperty(string)) {
        result?.push(...masterData[string]);
      }
    });
    return result;
  };

  const handleChange = (e) => {
    if (e.target.name === "documentType") {
      changeNoteData[e.target.name] = e.target.value;
      let array = getDocumentsByType(e.target.value);

      setDocuments(array);
    } else if (
      e.target.name === "proposedforChange" ||
      e.target.name === "reasonforChange"
    ) {
      changeNoteData[e.target.name] = [];
      if (changeNoteData[e.target.name].at(-1) != e.target.value) {
        changeNoteData[e.target.name].push(e.target.value);
      } else {
        changeNoteData[e.target.name] = [e.target.value];
      }
    } else if (e.target.name === "selectedDocuments") {
      // Get unique document names
      const uniqueDocumentNames = [
        ...new Set(e?.target?.value?.map((item) => item?.documentName)),
      ];
      let finalFilteredDocumentData = [];
      // Filter the results where isFormat is true and the documentName is in uniqueDocumentNames
      const filteredResults = filterDataBase?.filter(
        (item) =>
          item?.isFormat &&
          uniqueDocumentNames?.some(
            (name) => name?.toLowerCase() === item?.documentName?.toLowerCase()
          )
      );
      // Push filteredResults array directly into finalFilteredDocumentData
      if (filteredResults?.length) {
        const finalFilteredDocumentDataForDocument = (
          filteredResults || []
        )?.filter((item) => item?.revisionNumber !== null); // Filter out items without a revisionNumber

        finalFilteredDocumentData.push(...finalFilteredDocumentDataForDocument);
      }

      let filteredValuesRecords;
      if (filteredResults?.length) {
        filteredValuesRecords = e?.target?.value?.filter((item) =>
          filteredResults?.some(
            (result) =>
              result?.documentName?.toLowerCase() !==
              item?.documentName?.toLowerCase()
          )
        );
      } else {
        filteredValuesRecords = e?.target?.value;
      }

      // Push filteredValuesRecords array directly into finalFilteredDocumentData
      if (filteredValuesRecords?.length) {
        finalFilteredDocumentData.push(...filteredValuesRecords);
      }

      changeNoteData[e.target.name] = finalFilteredDocumentData;
    } else {
      changeNoteData[e.target.name] = e.target.value;
    }
    setChangeNoteData((pre) => ({ ...pre }));
  };

  const handleFeedbackChange = (e) => {
    feedbackData[e?.target?.name] = e?.target?.value;
    setFeedbackData((pre) => ({ ...pre }));
    setError(false);
    setEmailError(false);
    setFeedBackResponce(null);
    setCopied(false);
  };

  const handleInternalCircularChange = (e) => {
    const { name, value } = e.target;
    setInternalCircular((prev) => ({
      ...prev,
      [name]: value,
    }));

    // Clear errors on input
    setInternalCircularErrors((prev) => ({
      ...prev,
      [name]: "",
    }));
    if (name === "selectType" && value === "Select Users") {
      handleSelectUser("Select Users");
    }
    if (name === "selectType" && value === "Department") {
      handleSelectUser("Department", e.target.value);
    }
    if (name === "selectDepartment") {
      getUserNamesBySelectedDepartments(allRoles, e.target.value);
    }
    if (name === "communicatedTo") {
      getSelectedUserData(allRoles, e.target.value);
    }
  };

  const getSelectedUserData = (allRoles, selectedUsers) => {
    const selectedUserData = [];

    selectedUsers?.forEach((userString) => {
      const [name, designation] = userString.split(" (");

      const cleanDesignation = designation.replace(")", "");

      const matchedUser = allRoles?.find(
        (role) =>
          role?.userName?.name === name.trim() &&
          role?.designation === cleanDesignation?.trim()
      );

      if (matchedUser && matchedUser?.userName?.userId) {
        selectedUserData.push({
          userId: matchedUser?.userName?.userId,
          designation: matchedUser?.designation,
          userName: matchedUser?.userName?.name,
        });
      }
    });
    setDesignationUserList(selectedUserData);
  };

  const getUserNamesBySelectedDepartments = (data, selectedDepartments) => {
    const usersFromDepartments = data.filter((user) =>
      selectedDepartments.includes(user.department)
    );
    setSelectedDepartments(selectedDepartments);

    const userDetails = usersFromDepartments
      .filter((user) => user.userName && user.userName.name !== "")
      .map((user) => ({
        name: user.userName.name,
        designation: user.designation,
        userId: user.userName.userId,
      }));
    setDesignationUserList(userDetails);
  };

  const handleSelectUser = async (value, selectedDepartments = null) => {
    try {
      let reqObj = {
        orgId: userData.orgId,
      };
      let uri = props?.commonUrl?.GET_ALL_ROLES_API;
      let result = await props?.ApiServices.callServicePostWithBodyData(
        uri,
        reqObj
      );
      if (result?.meta?.success) {
        setAllRoles(result?.data);
        if (value == "Select Users") {
          const excludeIds = [userData?._id];
          const namesArray = result?.data
            .filter(
              (user) =>
                Object.keys(user.userName).length > 0 &&
                user.userName.name !== "" &&
                !excludeIds.includes(user.userName.userId)
            )
            .map((user) => `${user.userName.name} (${user.designation})`);
          setUserList(namesArray);
        } else if (value == "Department") {
          const department = getAllDepartments(result?.data);
          getUserNamesBySelectedDepartments(result?.data, selectedDepartments);
          setUserList(department);
        }
      }
    } catch (error) {
      console.error("GET_ALL_ROLES_API ERROR", error);
    }
  };
  const getAllDepartments = (data) => {
    const uniqueDepartments = [
      ...new Set(
        data
          .filter(
            (item) =>
              item.department &&
              typeof item.department === "string" &&
              item.department !== ""
          )
          .map((item) => item.department)
      ),
    ];

    return uniqueDepartments;
  };

  const validateInternalCircular = () => {
    let valid = true;
    const newErrors = {};

    // Required fields to validate
    const fieldsToValidate = [
      "circularInitiator.name", // Nested value check
      // "department",
      "date",
      "selectType",
      "description",
    ];

    // Check if each required field has a valid value
    fieldsToValidate.forEach((field) => {
      const fieldValue = field.includes(".")
        ? field.split(".").reduce((acc, part) => acc?.[part], internalCircular)
        : internalCircular[field];

      if (
        !fieldValue ||
        (Array.isArray(fieldValue) && fieldValue.length === 0)
      ) {
        newErrors[field] = `${field.replace(/([A-Z])/g, " $1")} is required`;
        valid = false;
      }
    });

    // Conditional validation for 'Select Department' and 'Communicated To'
    if (
      internalCircular.selectType === "Department" &&
      !internalCircular.selectDepartment
    ) {
      newErrors.selectDepartment =
        "Department is required for 'Department' type";
      valid = false;
    }
    if (
      internalCircular.selectType === "Select Users" &&
      internalCircular.communicatedTo.length === 0
    ) {
      newErrors.communicatedTo =
        "'Communicated To' is required for 'Select Users' type";
      valid = false;
    }

    // Set errors in state if any
    setInternalCircularErrors(newErrors);
    return valid;
  };

  const handleInternalCircularSubmit = async () => {
    if (validateInternalCircular()) {
      let reqObj = {
        orgId: userData?.orgId,
        clientUserId: userData?._id,
        documentName: "Internal Communication",
        prefixType: "FormatPrefixes",
        type: internalCircular?.selectType,
        date: internalCircular?.date,
        clientUserId: userData?._id,
        document: {
          initiatedUserId: getUserData(userData),
          description: internalCircular?.description,
          ...(internalCircular?.selectType === "Department" && {
            departmentName: selectedDepartments,
          }),
          ...(internalCircular?.selectType === "All Users"
            ? { receiverUserData: allRoles }
            : { receiverUserData: designationUserList }),
        },
      };
      let notification;
      if (internalCircular?.selectType == "All Users") {
        const excludeIds = [userData?._id];
        notification = getAllUserIds(allRoles, excludeIds);
      } else {
        notification = getAllUserIdsForDepartment(designationUserList);
      }

      let uri = CREATE_INTERNAL_COMMUNICATION;
      let result = await props?.ApiServices.callServicePostWithBodyData(
        uri,
        reqObj
      );
      showToast(result);
      if (result?.meta?.success) {
        const reqObj = {
          dataArray: [
            {
              recipients: notification,
              status: "Internal Circular Filled By User",
              msg: "Internal Circular is submitted by user, please see it",
              location: `internal-communication/${result?.data?._id}`,
            },
          ],
        };
        await props?.ApiServices.callServicePostWithBodyData(
          props?.commonUrl?.GET_NOTIFICATIONS_QAHEAD,
          reqObj
        );
        setSelectedModal("");
        setInternalCircular({
          circularInitiator: { name: userData?.name, _id: userData?._id },
          department: userData?.role,
          date: new Date().toISOString().split("T")[0],
          selectType: "All Users",
          selectDepartment: "",
          communicatedTo: [],
          description: "",
        });
        setInternalCircularErrors({});
        window.dispatchEvent(new Event("internalCommunicationUpdate"));
      }
    }
  };
  const getAllUserList = async () => {
    try {
      let reqObj = {
        orgId: userData.orgId,
      };
      let uri = props?.commonUrl?.GET_ALL_ROLES_API;
      let result = await props?.ApiServices.callServicePostWithBodyData(
        uri,
        reqObj
      );
      if (result?.meta?.success) {
        setAllRoles(result.data);
      }
    } catch (error) {
      console.error("GET_ALL_ROLES_API ERROR", error);
    }
  };

  const getDocuNumber = () => {
    let allDocumentNumbers =
      changeNoteData.selectedDocuments &&
      changeNoteData.selectedDocuments.map(
        (item, index) => item.documentNumber
      );
    setChangeNoteData((pre) => ({
      ...pre,
      documentNo: allDocumentNumbers.join(","),
    }));
  };

  const checkAuthorization = (details) => {
    if (details === userData?._id) {
      return true;
    }
    return false;
  };

  const handleChangeProposeChange = (e, index) => {
    changeNoteData.selectedDocuments[index]["proposeChange"] = e?.target?.value;
    setChangeNoteData((pre) => ({ ...pre }));
  };

  const currentDate = new Date();
  changeNoteData["currentStatus"] = "Change Request Note Initiated";
  changeNoteData.status = changeNoteData?.status || {};
  changeNoteData.status.changeRequestedNoteInitiated = {
    lastUpdated: currentDate,
    createdAt: changeNoteData?.dateOfRequest,
    responsiblePerson: userData?._id,
    responsiblePersonName: userData?.name,
  };

  const handleChangeNote = async () => {
    try {
      const headUser =
        selectedRole === "MR"
          ? await getUsersDataByDepartment("MR", true, "MR")
          : await getUsersDataByDepartment(selectedRole, true, "topManagement");

      const currentDate = new Date();

      changeNoteData["currentStatus"] = "Change Request Note Initiated";
      changeNoteData.status = changeNoteData?.status || {};
      changeNoteData.assignedUserId =
        headUser?.map((user) => ({ userId: user.userId })) || [];
      changeNoteData.status.changeRequestedNoteInitiated = {
        lastUpdated: currentDate,
        createdAt: changeNoteData?.dateOfRequest,
        responsiblePerson: userData?._id,
        responsiblePersonName: userData?.name,
      };

      if (changeNoteData?.changeNoteId) {
        let authorization = checkAuthorization(
          changeNoteData?.requesterDetails?.userId
        );
        if (authorization) {
          let reqObj = {
            _id: changeNoteData?.changeNoteId,
            changeNoteData,
          };
          let uri = props?.commonUrl?.CREATE_DOC_CHANGE_NOTE;
          let result = await props?.ApiServices.callServicePutWithBodyData(
            uri,
            reqObj
          );

          if (result?.result?.type === "success") {
            dispatchFunction(result);
            setSelectedModal("");
            window.dispatchEvent(new Event("changeNoteUpdated"));
            let notificationObj = {
              dataArray: [
                {
                  recipients: headUser?.map((user) => ({
                    userId: user.userId,
                  })),
                  msg: "Please approve or reject change note request",
                  status: "Approval request",
                  location: `changeNoteHistory`,
                },
              ],
            };

            await sendNotification(notificationObj);
          }
        } else {
          showToast("You are not a requester for this change note !!");
        }
      } else {
        let reqObj = {
          orgId: userData?.orgId,
          changeNoteData: changeNoteData,
          documentName: "Change Note",
          prefixType: "ChangeNotePrefixes",
        };
        let uri = props?.commonUrl?.CREATE_DOC_CHANGE_NOTE;
        let result = await props?.ApiServices.callServicePostWithBodyData(
          uri,
          reqObj
        );
        if (result?.result?.type === "success") {
          dispatchFunction(result);
          setSelectedModal("");
          window.dispatchEvent(new Event("changeNoteUpdated"));
          let notificationObj = {
            dataArray: [
              {
                recipients: headUser?.map((user) => ({ userId: user.userId })),
                msg: "Please approve or reject change note request",
                status: "Approval request",
                location: `changeNoteHistory`,
              },
            ],
          };

          await sendNotification(notificationObj);
        }
      }
    } catch (error) {
      console.error("CREATE_DOC_CHANGE_NOTE API ERROR", error);
    }
  };

  // feedback submit handler
  const handleSubmitFeedback = async () => {
    try {
      if (feedbackData?.type === "External") {
        if (feedbackData?.subType === "Fill-Customer-Feedback") {
          if (formikRef.current && formikRef.current.submitForm) {
            formikRef.current.submitForm();
          }
        } else if (
          feedbackData?.subType === "Generate-Link-Invite" &&
          feedbackData?.userEmails?.length === 0
        ) {
          setEmailError(true);
          return;
        }
      }

      if (feedbackData?.type === "Internal" && !feedbackData?.message?.trim()) {
        setError(true);
        return;
      }
      let notificationArr = [];
      if (feedbackData?.type === "Internal") {
        const mdData = await getUsersDataByDepartment(
          "Managing Director",
          true,
          "topManagement"
        );
        const directorData = await getUsersDataByDepartment(
          "Director",
          true,
          "topManagement"
        );
        notificationArr.push(...mdData, ...directorData);
      } else if (feedbackData?.type === "External") {
        const [mdData, directorData, headUser] = await Promise.all([
          getUsersDataByDepartment("Managing Director", true, "topManagement"),
          getUsersDataByDepartment("Director", true, "topManagement"),
          getUsersDataByDepartment("Sales and Marketing Department", true),
        ]);

        notificationArr.push(...mdData, ...directorData);
        const transformed = transformUserDesignationObject(headUser);
        notificationArr.push(transformed);
      }
      let tempObj =
        feedbackData?.type === "Internal"
          ? {
              dataArray: [
                {
                  recipients: notificationArr?.map((user) => ({
                    userId: user?.userId,
                  })),
                  status: "Feedback Form Filled By User",
                  msg: "Feedback form submitted by user,please Review it",
                  location: `feedback/view/${getYearFromDate(new Date())}`,
                },
              ],
            }
          : feedbackData?.type === "External" &&
            feedbackData?.subType === "Fill-Customer-Feedback" && {
              dataArray: [
                {
                  recipients: notificationArr?.map((user) => ({
                    userId: user?.userId,
                  })),
                  status: "Feedback Form Filled By User",
                  msg: "Feedback form submitted by user,please Review it",
                  location: `feedback/view/${getYearFromDate(new Date())}`,
                },
              ],
            };

      let reqObj = {
        orgId: userData?.orgId,
        clientUserId: userData?._id,
        formatName: "Feedback",
        documentName: "Feedback",
        prefixType: "FormatPrefixes",
        type: feedbackData?.type,
        subType:
          feedbackData?.type === "External"
            ? ["Generate-Link", "Generate-Link-Invite"].includes(
                feedbackData?.subType
              )
              ? "Generate-Link"
              : feedbackData?.subType
            : "",
        document: {
          userData: getUserData(userData),
          ...(feedbackData?.type === "Internal" && {
            message: feedbackData?.message,
            notificationUsers: notificationArr,
          }),
          ...(feedbackData?.type === "External" &&
            feedbackData?.subType === "Fill-Customer-Feedback" && {
              feedbackData: {
                status: {
                  createdAt: null,
                  comment: null,
                  currentStatus: "Pending",
                  clientUserId: null,
                },
                ...formikRef.current.feedbackCustomerData.values,
              },
              notificationUsers: notificationArr,
            }),
          ...(feedbackData?.type === "External" &&
            ["Generate-Link", "Generate-Link-Invite"].includes(
              feedbackData?.subType
            ) && {
              notificationData: {
                recipients: notificationArr?.map((user) => ({
                  userId: user?.userId,
                })),
                status: "Feedback Form Filled By User",
                msg: "Feedback form submitted by user,please Review it",
                location: `feedback/`,
              },
              responces: [],
              domainName: `${window.location.origin}/feedback-guest/`,
              userEmails:
                feedbackData?.subType === "Generate-Link-Invite"
                  ? feedbackData?.userEmails
                  : undefined,
            }),
        },
      };
      let formatResult, resultObj;
      if (
        feedbackData?.type === "External" &&
        feedbackData?.subType === "Fill-Customer-Feedback"
      ) {
        if (
          formikRef.current?.feedbackCustomerData?.dirty &&
          formikRef.current?.feedbackCustomerData?.isValid
        ) {
          const formData = new FormData();
          formData.append("data", JSON.stringify(reqObj));
          formatResult = await props?.ApiServices.callServicePostWithFormData(
            ADD_FEEDBACK_DATA,
            formData
          );
          if (formatResult?.meta?.success) {
            setSelectedModal("");
            setFeedBackResponce(null);
            showToast(formatResult);
            tempObj.dataArray[0].location = `feedback/${formatResult?.data?._id}`;
            await sendNotification(tempObj);
            window.dispatchEvent(new Event("feedbackUpdate"));
          } else {
            showToast(formatResult);
          }
        }
      } else if (
        feedbackData?.type === "External" &&
        feedbackData?.subType !== "Fill-Customer-Feedback"
      ) {
        const formData = new FormData();
        formData.append("data", JSON.stringify(reqObj));
        formatResult = await props?.ApiServices.callServicePostWithFormData(
          ADD_FEEDBACK_DATA,
          formData
        );
        if (formatResult?.meta?.success) {
          showToast(formatResult);
          resultObj = {
            link: formatResult?.data?.document?.domainLink ?? "-",
          };
          if (feedbackData?.subType === "Generate-Link-Invite") {
            setSelectedModal("");
          }
          setFeedBackResponce(resultObj);
          window.dispatchEvent(new Event("feedbackUpdate"));
        } else {
          showToast(formatResult);
        }
      } else {
        const formData = new FormData();
        formData.append("data", JSON.stringify(reqObj));
        feedbackData?.uploadedFile?.file !== undefined &&
          formData.append("images", feedbackData?.uploadedFile?.file);
        formatResult = await props?.ApiServices.callServicePostWithFormData(
          ADD_FEEDBACK_DATA,
          formData
        );

        if (formatResult?.meta?.success) {
          setSelectedModal("");
          setFeedBackResponce(null);
          showToast(formatResult);
          tempObj.dataArray[0].location = `feedback/${formatResult?.data?._id}`;
          await sendNotification(tempObj);
          window.dispatchEvent(new Event("feedbackUpdate"));
        } else {
          showToast(formatResult);
        }
      }
    } catch (error) {
      console.error("CREATE_FORMATS ERROR", error);
    }
  };

  // for copy link
  const copyToClipboard = () => {
    const linkText = linkRef.current?.textContent;
    if (copy(linkText)) {
      showToast("Linked Copied Successfully", true);
      setCopied(true);
      setError(false);
      setEmailError(false);
    } else {
      showToast("Error in copying link");
      setError(false);
      setEmailError(false);
    }
  };

  const handleFileUpload = (e) => {
    const file = e.target.files?.[0]; // Single file with optional chaining
    const allowedTypes = [
      "application/pdf",
      "application/msword",
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
      "image/jpeg",
      "image/png",
    ];

    if (!allowedTypes.includes(file?.type)) {
      setFileError(
        "Only PDF, Word documents, and image files (JPEG, PNG) are allowed."
      );
    } else {
      setFileError("");
      const preview = file?.type?.startsWith("image/")
        ? URL.createObjectURL(file)
        : null;
      setFeedbackData((prevState) => ({
        ...prevState,
        uploadedFile: { file, preview },
      }));
    }
  };

  // Function to handle opening/closing the modal
  const handleAgentModal = () => {
    setSelectedModal((prevValue) => (prevValue === "agent" ? "" : "agent"));
    setAgentData({
      name: "",
      address: "",
    });
    setAgentErrors({});
  };

  // Function to handle changes to the agent form inputs
  const handleAgentChange = (e) => {
    const { name, value } = e.target;
    setAgentData((prev) => ({
      ...prev,
      [name]: value,
    }));

    // Clear errors on input change
    setAgentErrors((prev) => ({
      ...prev,
      [name]: "",
    }));
  };

  // agent modal handler
  const handleAgentSubmit = async () => {
    const errors = {
      name: agentData?.name ? "" : "Name is required",
      address: agentData?.address ? "" : "Address is required",
    };

    const filteredErrors = Object.fromEntries(
      Object.entries(errors).filter(([_, v]) => v)
    );

    if (Object.keys(filteredErrors).length) {
      return setAgentErrors(filteredErrors);
    }

    let headUser = await getUsersDataByDepartment(
      "Sales and Marketing Department",
      true
    );

    const reqObj = {
      document: {
        ...agentData,
        createdBy: getUserData(userData),
        approveBy: transformUserDesignationObject(headUser),
      },
      status: "initiated",
      documentSubmitted: false,
      history: [
        {
          documentName: "Agent Created",
          type: "initiated",
          time: new Date().toISOString(),
          userData: getUserData(userData),
        },
      ],
      documentName: "Agent Data",
      prefixType: "FormatPrefixes",
    };

    const result = await props?.ApiServices.callServicePostWithBodyData(
      ADD_DATA,
      reqObj
    );

    showToast(result);
    if (result?.meta?.success) {
      handleAgentModal();
      let notificationObj = {
        dataArray: [
          {
            recipients: [{ userId: headUser?.userData?.userId }],
            msg: "A new agent request has been received. Please make a decision.",
            status: "New agent request received.",
            location: `customer-related-process-and-distribution/agreement/${result?.data?._id}`,
          },
        ],
      };
      await sendNotification(notificationObj);
      window.dispatchEvent(new Event("agentDataUpdate"));
    }
  };

  const iconBackColorOpen =
    theme.palette.mode === ThemeMode.DARK ? "secondary.200" : "secondary.200";
  const iconBackColor =
    theme.palette.mode === ThemeMode.DARK
      ? "background.default"
      : "secondary.100";

  const handleService = () => {
    navigate(`servicing/customer-service-form`);
  };

  const buttonData = {
    general: [
      handleGetPermission(
        "Customer Service Form",
        Constants.KEY_RBAC_CREATE
      ) && {
        text: "Customer Service Form",
        onClick: handleService,
      },
      handleGetPermission("Feedback", Constants.KEY_RBAC_CREATE) && {
        text: "Feedback",
        onClick: handleFeedbackModal,
      },
      handleGetPermission(
        "Internal-External Communication",
        Constants.KEY_RBAC_CREATE
      ) && {
        text: "Internal communication",
        onClick: handleInternalCircularModal,
      },
      handleGetPermission(
        "List of Customer Property",
        Constants.KEY_RBAC_READ
      ) && {
        text: "Customer Property",
        onClick: () => navigate("customer-property/list-of-customer-property"),
      },
      handleGetPermission(
        "Procedure for Work Environment",
        Constants.KEY_RBAC_READ
      ) && {
        text: "Work Environment",
        onClick: () => navigate("work-environment/environment"),
      },
      handleGetPermission("Customer Complaint", Constants.KEY_RBAC_CREATE) && {
        text: "Add-Complaint",
        onClick: () => navigate("customer-complaint/add-complaint"),
      },
      handleGetPermission(
        "Generate Inprocess Record",
        Constants.KEY_RBAC_CREATE
      ) && {
        text: "Generate Inprocess Record",
        onClick: () =>
          navigate("monitoring-and-measurement-of-product/incoming"),
      },
      handleGetPermission("Change Management", Constants.KEY_RBAC_CREATE) && {
        text: "Change Note",
        onClick: () => handleChangeNoteModal(false),
      },
      handleGetPermission(
        "Communication with Requlatory Bodies - Mega Menu Section",
        Constants.KEY_RBAC_CREATE
      ) && {
        text: "Communication With RegulatoryBody",
        onClick: () => navigate("/cwrb/communication-with-regulatory-body"),
      },
    ],
    CAPA: [
      handleGetPermission("Corrective Action", Constants.KEY_RBAC_CREATE) && {
        text: "Corrective action",
        onClick: () => navigate("capa/corrective"),
      },
      handleGetPermission("Preventive Action", Constants.KEY_RBAC_CREATE) && {
        text: "Preventive Action",
        onClick: () => navigate("capa/preventive"),
      },
    ],
    customerRelatedProcessAndDistribution: [
      handleGetPermission(
        "Distributor Agreement",
        Constants.KEY_RBAC_CREATE
      ) && {
        text: "Generate New Agent",
        onClick: handleAgentModal,
      },
      handleGetPermission(
        "Customer Feedback Form",
        Constants.KEY_RBAC_CREATE
      ) && {
        text: "Generate Order Form",
        onClick: () => handleGenerateOrderModal(),
      },
    ],
    Recall: [
      handleGetPermission(
        "Product Recall Register",
        Constants.KEY_RBAC_CREATE
      ) && {
        text: "Recall",
        onClick: () => (
          <Button onClick={setOpenRecallModal(true)}>Recall</Button>
        ),
      },
      handleGetPermission("Recall List", Constants.KEY_RBAC_READ) && {
        text: "Recall List",
        onClick: () => navigate("advisory-recall/recall-list"),
      },
      handleGetPermission("Advisory Notice", Constants.KEY_RBAC_CREATE) && {
        text: "Advisory-Notice",
        onClick: () => navigate("/advisory-recall/advisory-notice"),
      },
    ],
  };

  // Filter out falsy values for each key in buttonData
  Object.keys(buttonData)?.forEach((key) => {
    buttonData[key] = buttonData[key]?.filter(Boolean);

    // If no valid buttons, delete the key
    if (buttonData[key]?.length === 0) {
      delete buttonData[key];
    }
  });

  const createButton = (text, onClick, gradient1, gradient2, shadow) => ({
    text,
    onClick,
  });

  const createButtonsForCategory = (category) => {
    return (
      buttonData[category]
        ?.filter(Boolean)
        ?.map(({ text, onClick }) => createButton(text, onClick)) || []
    );
  };

  useEffect(() => {
    const handleUpdateData = () => {
      getAllDocData();
      getChangeNoteById(changeNoteDataFromLS?.changeNoteId);
    };

    window.addEventListener("changeNote", handleUpdateData);

    return () => {
      window.removeEventListener("changeNote", handleUpdateData);
    };
  }, []);

  useEffect(() => {
    const handleUpdateData = () => {
      handleFeedbackModal();
    };

    window.addEventListener("createFeedback", handleUpdateData);

    return () => {
      window.removeEventListener("createFeedback", handleUpdateData);
    };
  }, []);

  useEffect(() => {
    const handleUpdateData = () => {
      handleInternalCircularModal();
    };

    window.addEventListener("createInternalCommunication", handleUpdateData);

    return () => {
      window.removeEventListener(
        "createInternalCommunication",
        handleUpdateData
      );
    };
  }, []);

  useEffect(() => {
    const handleUpdateData = () => {
      handleAgentModal();
    };

    window.addEventListener("agentModalOpen", handleUpdateData);

    return () => {
      window.removeEventListener("agentModalOpen", handleUpdateData);
    };
  }, []);

  useEffect(() => {
    const handleUpdateData = () => {
      setSelectedModal("customer-servicing-record");
    };

    window.addEventListener("customer-servicing-record", handleUpdateData);

    return () => {
      window.removeEventListener("customer-servicing-record", handleUpdateData);
    };
  }, []);

  const handleSubmit = () => {
    const validationErrors = validateFields();

    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
    } else {
      setErrors({});
      changeNoteEdit ? handleChangeNoteEdit() : handleChangeNote();
    }
  };

  // Function to clear error when a field is changed
  const clearError = (field) => {
    setErrors((prevErrors) => ({ ...prevErrors, [field]: null }));
  };

  return (
    <>
      {isOpenNotification ? (
        <DynamicNotification
          open={isOpenNotification}
          setOpen={setIsOpenNotification}
          isMultipleDepartment={false}
          isMultipleUser={false}
          handleSubmit={handleSubmitMasterList}
        />
      ) : null}
      <Box sx={{ flexShrink: 0, ml: 0.5 }}>
        <IconButton
          color="secondary"
          variant="light"
          aria-label="open Btns"
          ref={anchorRef}
          aria-controls={open ? "Btns-grow" : undefined}
          aria-haspopup="true"
          onClick={handleToggle}
          size="large"
          sx={{
            color: "secondary.main",
            bgcolor: open ? iconBackColorOpen : iconBackColor,
            p: 1,
          }}
        >
          <Category />
        </IconButton>

        <Popper
          placement="bottom-end"
          open={open}
          anchorEl={anchorRef.current}
          role={undefined}
          transition
          disablePortal
          popperOptions={{
            modifiers: [
              {
                name: "offset",
                options: { offset: isSmallScreen ? [170, 9] : [0, 9] },
              },
            ],
          }}
          sx={{
            maxHeight: "500px",
            overflow: "hidden",
          }}
        >
          {({ TransitionProps }) => (
            <Transitions
              type="grow"
              position="top-right"
              in={open}
              {...TransitionProps}
            >
              {isSmallScreen ? (
                <Paper
                  sx={{
                    boxShadow: theme.customShadows.z1,
                    width: {
                      md: `calc(100vw - 200px)`,
                      lg: `calc(100vw - ${DRAWER_WIDTH + 100}px)`,
                      xl: `calc(100vw - ${DRAWER_WIDTH + 140}px)`,
                    },
                    maxWidth: 1024,
                    maxHeight: "500px",
                    borderRadius: 1.5,
                    overflow: "hidden",
                  }}
                >
                  <ClickAwayListener onClickAway={handleClose}>
                    <MainCard elevation={0} border={false} content={false}>
                      <Grid container>
                        <Grid
                          item
                          xs={4}
                          sx={{
                            color: "common.white",
                            bgcolor:
                              theme?.palette?.mode === ThemeMode?.DARK
                                ? "primary.400"
                                : "primary.darker",
                            position: "relative",
                            "&:after": {
                              content: '""',
                              background: `url("${Waves}") 100% / cover no-repeat`,
                              position: "absolute",
                              top: "41%",
                              left: 0,
                              right: 0,
                              bottom: 0,
                              zIndex: 1,
                              opacity: 0.5,
                            },
                          }}
                        >
                          <Box
                            sx={{
                              p: 4.5,
                              pb: 3,
                              position: "inherit",
                              zIndex: 2,
                            }}
                          >
                            <Stack sx={{ color: "background.paper" }}>
                              <Typography
                                variant="h2"
                                sx={{ fontSize: "1.875rem", mb: 1 }}
                              >
                                Explore Features
                              </Typography>
                              <Typography variant="h6">
                                Explore our features to kickstart your work."
                              </Typography>
                            </Stack>
                          </Box>
                        </Grid>
                        <Grid item xs={8}>
                          <Box
                            sx={{
                              p: 4,
                              "& .MuiList-root": { pb: 0 },
                              "& .MuiListSubheader-root": { p: 0, pb: 1.5 },
                              "& .MuiListItemButton-root": {
                                p: 0.5,
                                "&:hover": {
                                  bgcolor: "transparent",
                                  "& .MuiTypography-root": {
                                    color: "primary.main",
                                  },
                                },
                              },
                              "& .MuiListItemIcon-root": { minWidth: 16 },
                            }}
                          >
                            <Grid
                              container
                              spacing={6}
                              sx={{
                                overflowY: "scroll",
                                maxHeight: "500px",
                                "&::-webkit-scrollbar": {
                                  width: "5px",
                                },
                                "&::-webkit-scrollbar-thumb": {
                                  backgroundColor: theme.palette.primary.main,
                                  borderRadius: "10px",
                                },
                                "&::-webkit-scrollbar-track": {
                                  backgroundColor:
                                    theme.palette.background.paper,
                                },
                              }}
                            >
                              {Object?.keys(buttonData)?.map((category) => (
                                <Grid item xs={6} key={category}>
                                  <List
                                    component="nav"
                                    aria-labelledby={`nested-list-${category}`}
                                    subheader={
                                      <ListSubheader
                                        id={`nested-list-${category}`}
                                      >
                                        <Typography
                                          variant="subtitle1"
                                          color="text.primary"
                                          sx={{
                                            textTransform: "capitalize",
                                          }}
                                        >
                                          {camelCaseToString(category)}
                                        </Typography>
                                      </ListSubheader>
                                    }
                                  >
                                    {createButtonsForCategory(category)
                                      ?.length > 0
                                      ? createButtonsForCategory(category)?.map(
                                          (button, i) => (
                                            <ListItemButton
                                              key={i}
                                              onClick={(e) => {
                                                if (button?.onClick)
                                                  button.onClick(e);
                                                handleClose(e);
                                              }}
                                            >
                                              <ListItemIcon>
                                                <Dot
                                                  size={6}
                                                  color="secondary"
                                                  variant="outlined"
                                                />
                                              </ListItemIcon>
                                              <ListItemText
                                                primary={button?.text}
                                              />
                                            </ListItemButton>
                                          )
                                        )
                                      : null}
                                  </List>
                                </Grid>
                              ))}
                            </Grid>
                          </Box>
                        </Grid>
                      </Grid>
                    </MainCard>
                  </ClickAwayListener>
                </Paper>
              ) : (
                <Paper
                  sx={{
                    marginLeft: 2,
                    marginRight: 2,
                    boxShadow: theme.customShadows.z1,
                    maxWidth: 350,
                    overflowY: "scroll",
                    maxHeight: "500px",
                    borderRadius: 1.5,
                    "&::-webkit-scrollbar": {
                      width: "5px",
                    },
                    "&::-webkit-scrollbar-thumb": {
                      backgroundColor: theme.palette.primary.main,
                      borderRadius: "10px",
                    },
                    "&::-webkit-scrollbar-track": {
                      backgroundColor: theme.palette.background.paper,
                    },
                  }}
                >
                  <ClickAwayListener onClickAway={handleClose}>
                    <MainCard border={false} content={false}>
                      <CardContent sx={{ p: 2, pb: 0 }}>
                        {Object.keys(buttonData).map((category) => (
                          <Box key={category} sx={{ mb: 1 }}>
                            <Typography
                              variant="subtitle2"
                              color="text.primary"
                              sx={{
                                textTransform: "capitalize",
                                fontSize: "11px",
                                // mb: 1,
                              }}
                            >
                              {camelCaseToString(category)}
                            </Typography>
                            <Divider />
                            <List
                              component="nav"
                              sx={{
                                p: 0,
                                "& .MuiListItemIcon-root": { minWidth: 32 },
                                "& .MuiDivider-root": { my: 1 },
                              }}
                            >
                              {createButtonsForCategory(category)?.length > 0 &&
                                createButtonsForCategory(category)?.map(
                                  ({ text, onClick }, i) => (
                                    <ListItemButton
                                      key={i}
                                      onClick={(e) => {
                                        if (onClick) onClick(e);
                                        handleClose(e);
                                      }}
                                    >
                                      <ListItemText primary={text} />
                                    </ListItemButton>
                                  )
                                )}
                            </List>
                          </Box>
                        ))}
                      </CardContent>
                      <Divider />
                    </MainCard>
                  </ClickAwayListener>
                </Paper>
              )}
            </Transitions>
          )}
        </Popper>
      </Box>

      {selectedModal === "changeNote" && (
        <CustomDialog
          open={selectedModal}
          onCancel={(e) => handleChangeNoteModal(e)}
          maxWidth={"md"}
        >
          <DialogTitle>CHANGE NOTES</DialogTitle>
          <Divider />
          {masterData != null && Object.keys(masterData).length > 0 ? (
            <>
              {" "}
              <DialogContent sx={{ p: 2.5 }}>
                <Grid container spacing={3}>
                  <Grid item xs={12} md={6}>
                    <FormControl fullWidth>
                      <InputLabel
                        id="demo-simple-select-label"
                        sx={{
                          marginBottom: "0.5rem",
                        }}
                      >
                        Standard
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        placeholder="Select Standard"
                        name="standard"
                        value={changeNoteData?.standard}
                        onChange={(e) => handleChange(e)}
                      >
                        {standardOption.map((item, index) => (
                          <MenuItem key={index} value={item}>
                            {item}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>

                  <Grid item xs={12} md={6}>
                    <FormControl fullWidth error={!!errors?.documentType}>
                      <InputLabel
                        id="demo-simple-select-label"
                        sx={{
                          marginBottom: "0.5rem",
                        }}
                      >
                        Document Type
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        multiple
                        placeholder="Select Standard"
                        name="documentType"
                        value={changeNoteData?.documentType}
                        onChange={(e) => {
                          handleChange(e);
                          clearError("documentType");
                        }}
                        renderValue={(selected) => (
                          <Box
                            sx={{
                              display: "flex",
                              flexWrap: "wrap",
                              gap: 0.5,
                            }}
                          >
                            {selected.map((value) => (
                              <Chip
                                key={value}
                                label={value}
                                variant="outlined"
                                color="primary"
                                size="small"
                              />
                            ))}
                          </Box>
                        )}
                      >
                        {docTypeOption?.map((item, index) => (
                          <MenuItem key={index} value={item}>
                            {item}
                          </MenuItem>
                        ))}
                      </Select>
                      {errors?.documentType && (
                        <FormHelperText>{errors?.documentType}</FormHelperText>
                      )}
                    </FormControl>
                  </Grid>

                  <Grid item xs={12} md={6}>
                    <FormControl fullWidth error={!!errors?.selectedDocuments}>
                      <InputLabel id="demo-multiple-chip-label">
                        Selected Documents
                      </InputLabel>
                      <Select
                        labelId="demo-multiple-chip-label"
                        id="demo-multiple-chip"
                        multiple
                        name="selectedDocuments"
                        input={
                          <OutlinedInput
                            id="select-multiple-chip"
                            placeholder="Chip"
                          />
                        }
                        value={changeNoteData?.selectedDocuments}
                        onChange={(e) => {
                          handleChange(e);
                          getDocuNumber();
                          clearError("selectedDocuments");
                        }}
                        renderValue={(selected) => (
                          <Box
                            sx={{
                              display: "flex",
                              flexWrap: "wrap",
                              gap: 0.5,
                            }}
                          >
                            {selected.map((value) => (
                              <Chip
                                key={value.documentName}
                                label={value.documentName || ""}
                                variant="outlined"
                                color="primary"
                                size="small"
                              />
                            ))}
                          </Box>
                        )}
                      >
                        {documents?.length > 0 ? (
                          documents
                            ?.filter((item) =>
                              filterDataBase?.some(
                                (doc) =>
                                  doc?.documentName?.toLowerCase() ===
                                    item?.documentName?.toLowerCase() &&
                                  ["absolute", "original"].includes(
                                    doc?.documentState
                                  )
                              )
                            )
                            .map((item, index) => (
                              <MenuItem key={index} value={item}>
                                {item?.documentName}
                              </MenuItem>
                            ))
                        ) : (
                          <MenuItem key={"index"} value="">
                            Select Document Type First
                          </MenuItem>
                        )}
                      </Select>
                      {errors?.selectedDocuments && (
                        <FormHelperText>
                          {errors?.selectedDocuments}
                        </FormHelperText>
                      )}
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <LocalizationProvider
                      fullWidth
                      dateAdapter={AdapterDateFns}
                    >
                      <DesktopDatePicker
                        id="outlined-basic"
                        format="MM/dd/yyyy"
                        name="dateOfRequest"
                        sx={{
                          width: "100%",
                        }}
                        label="Date of Request"
                        value={new Date(changeNoteData?.dateOfRequest)}
                        disabled
                      />
                    </LocalizationProvider>
                  </Grid>
                  {changeNoteData?.selectedDocuments?.length > 0 && (
                    <>
                      <Grid item xs={12} md={12}>
                        <InputLabel
                          id="suggestion"
                          sx={{
                            marginBottom: "0.5rem",
                          }}
                        >
                          Proposed Change
                        </InputLabel>
                        <MainCard title="DOCUMET DETAILS">
                          <TableContainer component={Paper}>
                            <Table>
                              <TableHead>
                                <TableRow>
                                  <TableCell>Document Number</TableCell>
                                  <TableCell>Document Name</TableCell>
                                  <TableCell>Revision Number</TableCell>
                                  <TableCell>Revision Date</TableCell>
                                  <TableCell>Proposed Changes</TableCell>
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                {changeNoteData?.selectedDocuments?.length ? (
                                  changeNoteData?.selectedDocuments?.map(
                                    (doc, index) => (
                                      <TableRow key={index}>
                                        <TableCell>
                                          {doc?.documentNumber}
                                        </TableCell>
                                        <TableCell>
                                          {doc?.documentName}
                                        </TableCell>
                                        <TableCell>
                                          {doc?.revisionNumber}
                                        </TableCell>
                                        <TableCell>
                                          {doc?.revisionDate}
                                        </TableCell>
                                        <TableCell>
                                          <TextField
                                            style={{ width: "300px" }}
                                            multiline
                                            onBlur={(e) =>
                                              handleChangeProposeChange(
                                                e,
                                                index
                                              )
                                            }
                                            placeholder="Write proposed for change"
                                            name="proposedforChange"
                                            defaultValue={doc?.proposeChange}
                                          />
                                        </TableCell>
                                      </TableRow>
                                    )
                                  )
                                ) : (
                                  <TableRow>
                                    <TableCell colSpan={5} align="center">
                                      No Data Found
                                    </TableCell>
                                  </TableRow>
                                )}
                              </TableBody>
                            </Table>
                          </TableContainer>
                        </MainCard>
                      </Grid>
                    </>
                  )}
                  <Grid item xs={12} md={12}>
                    <TextField
                      id="outlined-basic"
                      label="Reason for Change"
                      multiline
                      rows={4}
                      fullWidth
                      placeholder="Write reason for change"
                      name="reasonforChange"
                      defaultValue={changeNoteData?.reasonforChange?.at(-1)}
                      onChange={handleChange}
                    />
                  </Grid>
                  {changeNoteData?.approverComment && (
                    <Grid item xs={12} md={12}>
                      <TextField
                        id="outlined-basic"
                        label="Approver Comment"
                        multiline
                        rows={4}
                        fullWidth
                        name="approverComment"
                        disabled
                        defaultValue={changeNoteData?.approverComment?.at(-1)}
                      />
                    </Grid>
                  )}
                </Grid>
              </DialogContent>
            </>
          ) : (
            <DialogContent
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "20rem",
              }}
            >
              <Card
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "15rem",
                  boxShadow: "0px 0px 15px rgba(0,0,0,0.2)",
                  width: "16rem",
                  fontWeight: "bold",
                }}
              >
                <CardContent>
                  <Typography variant="h5" component="div">
                    Create Master Table First!
                  </Typography>
                </CardContent>
              </Card>
            </DialogContent>
          )}
          <Divider />
          <DialogActions sx={{ p: 2.5 }}>
            <Grid
              container
              spacing={2}
              justifyContent="space-between"
              alignItems="center"
            >
              <Grid item xs={12} md={6}>
                <FormControl fullWidth error={!!errors?.selectedRole}>
                  <Select
                    value={selectedRole}
                    onChange={(e) => {
                      setSelectedRole(e.target.value);
                      clearError("selectedRole");
                    }}
                    displayEmpty
                  >
                    <MenuItem value="" disabled>
                      Notify to...
                    </MenuItem>
                    <MenuItem value="Managing Director">
                      Managing Director
                    </MenuItem>
                    <MenuItem value="Director">Director</MenuItem>
                    <MenuItem value="MR">MR</MenuItem>
                  </Select>
                  {errors?.selectedRole && (
                    <FormHelperText>{errors?.selectedRole}</FormHelperText>
                  )}
                </FormControl>
              </Grid>

              <Grid item xs={12} md={6}>
                <Stack
                  direction="row"
                  justifyContent="flex-end"
                  spacing={2}
                  alignItems="center"
                >
                  <Button
                    color="error"
                    variant="contained"
                    onClick={handleChangeNoteModal}
                  >
                    Cancel
                  </Button>
                  {Object.keys(masterData)?.length > 0 && (
                    <Button
                      color="success"
                      variant="contained"
                      onClick={() => handleSubmit()}
                    >
                      {changeNoteEdit ? "Edit Request" : "Initiate Request"}
                    </Button>
                  )}
                </Stack>
              </Grid>
            </Grid>
          </DialogActions>
        </CustomDialog>
      )}
      {selectedModal === "feedback" && (
        <CustomDialog
          open={selectedModal}
          onCancel={(e) => handleFeedbackModal(e)}
          maxWidth={"md"}
        >
          <DialogTitle>Feedback</DialogTitle>
          <Divider />
          <DialogContent>
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <FormControl fullWidth>
                  <InputLabel
                    id="demo-simple-select-label"
                    sx={{
                      marginBottom: "0.5rem",
                    }}
                  >
                    Feedback Type
                  </InputLabel>
                  <Select
                    placeholder="Select Standard"
                    name="type"
                    value={feedbackData?.type}
                    onChange={(e) => {
                      handleFeedbackChange(e);
                      setFeedbackData({
                        ...feedbackData,
                        userEmails: [],
                        uploadedFile: null,
                      });
                    }}
                  >
                    {feedbackOptions.map((item, index) => (
                      <MenuItem key={index} value={item}>
                        {item}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              {feedbackData?.type === "Internal" && (
                <>
                  <Grid item xs={12} md={12}>
                    <TextField
                      multiline
                      rows={4}
                      fullWidth
                      label="Write feedback"
                      name="message"
                      error={error}
                      helperText={error ? "Feedback message is required." : ""}
                      value={feedbackData?.message}
                      onChange={(e) => handleFeedbackChange(e)}
                    />
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <input
                      accept=".pdf,.doc,.docx,.jpg,.jpeg,.png"
                      style={{ display: "none" }}
                      id="contained-button-file"
                      type="file"
                      onChange={handleFileUpload}
                    />
                    <label htmlFor="contained-button-file">
                      <Button
                        variant="contained"
                        component="span"
                        startIcon={<CloudUpload />}
                      >
                        Upload Documents
                      </Button>
                    </label>
                    {fileError && (
                      <Typography
                        sx={{ marginLeft: "2rem" }}
                        variant="body2"
                        color="error"
                      >
                        {fileError}
                      </Typography>
                    )}

                    {feedbackData?.uploadedFile !== null && (
                      <div style={{ marginTop: "10px" }}>
                        <Typography variant="body1">
                          File uploaded:{" "}
                          {feedbackData?.uploadedFile?.file?.name}
                        </Typography>
                        <div>
                          <img
                            src={feedbackData?.uploadedFile?.preview}
                            alt="preview"
                            style={{
                              maxWidth: "100px",
                              maxHeight: "100px",
                              marginTop: "10px",
                            }}
                          />
                        </div>
                      </div>
                    )}
                  </Grid>
                </>
              )}

              {feedbackData?.type === "External" && (
                <>
                  <Grid item xs={12} md={6}>
                    <FormControl fullWidth>
                      <InputLabel
                        sx={{
                          marginBottom: "0.5rem",
                        }}
                      >
                        Customer Feedback Type
                      </InputLabel>
                      <Select
                        placeholder="Select SubType"
                        name="subType"
                        value={feedbackData?.subType}
                        onChange={(e) => handleFeedbackChange(e)}
                      >
                        {customerFeedbackOptions.map((item, index) => (
                          <MenuItem key={index} value={item}>
                            {item}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>

                  {feedbackData?.subType === "Generate-Link" &&
                    feedBackResponce?.link?.length > 0 && (
                      <div>
                        <span ref={linkRef} style={{ display: "none" }}>
                          {feedBackResponce.link}
                        </span>
                        <Button
                          variant="contained"
                          color={copied ? "success" : "primary"}
                          sx={{ margin: "1rem 1.5rem" }}
                          onClick={copyToClipboard}
                          endIcon={
                            copied ? (
                              <CheckCircleOutline />
                            ) : (
                              <ContentCopyOutlinedIcon />
                            )
                          }
                        >
                          {copied ? "Copied" : "Copy Link"}
                        </Button>
                      </div>
                    )}

                  {feedbackData?.subType === "Generate-Link-Invite" && (
                    <Grid item xs={12} md={6}>
                      <FormControl fullWidth>
                        <EmailSelector
                          emails={feedbackData?.userEmails}
                          setEmails={handleEmailsChange}
                        />
                        {emailError && (
                          <span
                            style={{
                              color: "red",
                              marginTop: "0.5rem",
                              display: "block",
                            }}
                          >
                            Email is required.
                          </span>
                        )}
                      </FormControl>
                    </Grid>
                  )}
                  {feedbackData?.subType === "Fill-Customer-Feedback" && (
                    <Grid item xs={12}>
                      <FeedbackForm privateRoute={true} formikRef={formikRef} />
                    </Grid>
                  )}
                </>
              )}
            </Grid>
          </DialogContent>
          <Divider />

          <DialogActions sx={{ p: 2.5 }}>
            <Stack
              direction="row"
              spacing={2}
              alignItems="center"
              justifyContent="flex-end"
            >
              <Button
                color="error"
                variant="contained"
                onClick={(e) => handleFeedbackModal(e)}
              >
                Cancel
              </Button>
              {feedBackResponce === null && (
                <Button
                  color="success"
                  variant="contained"
                  type="submit"
                  onClick={() => handleSubmitFeedback()}
                >
                  Submit
                </Button>
              )}
            </Stack>
          </DialogActions>
        </CustomDialog>
      )}
      {selectedModal === "internalCircular" && (
        <CustomDialog
          open={selectedModal}
          onCancel={(e) => handleInternalCircularModal(e)}
          maxWidth={"md"}
        >
          <DialogTitle>Internal Circular</DialogTitle>
          <Divider />
          <DialogContent>
            <Grid container spacing={2}>
              {[
                {
                  label: "Circular Initiator",
                  name: "circularInitiator.name",
                  isReadOnly: true,
                },
                { label: "Department", name: "department", isReadOnly: true },
                {
                  label: "Date",
                  name: "date",
                  type: "date",
                  InputLabelProps: { shrink: true },
                  isReadOnly: true,
                },
                {
                  label: "Select Type",
                  name: "selectType",
                  type: "select",
                  options: internalCircularOptions,
                },
                {
                  label: "Select Department",
                  name: "selectDepartment",
                  type: "select",
                  options: userList,
                  isMulti: true,
                  hidden: internalCircular?.selectType !== "Department",
                },
                {
                  label: "Communicated To",
                  name: "communicatedTo",
                  type: "select",
                  isMulti: true,
                  options: userList,
                  value: internalCircular?.communicatedTo || [],
                  hidden: internalCircular?.selectType !== "Select Users",
                },
              ].map((field, index) => {
                // Handle value extraction for single and multi-selects
                const value =
                  field.type === "select" && field.isMulti
                    ? Array.isArray(internalCircular[field.name])
                      ? internalCircular[field.name]
                      : []
                    : field.name.includes(".")
                    ? field.name
                        .split(".")
                        .reduce((acc, part) => acc?.[part], internalCircular)
                    : internalCircular[field.name];

                // Handle error extraction
                const error = field.name.includes(".")
                  ? field.name
                      .split(".")
                      .reduce(
                        (acc, part) => acc?.[part],
                        internalCircularErrors
                      )
                  : internalCircularErrors[field.name];

                return (
                  !field.hidden && (
                    <Grid item xs={12} sm={6} key={index}>
                      {field.type === "select" ? (
                        <FormControl
                          fullWidth
                          error={!!internalCircularErrors[field.name]}
                        >
                          <InputLabel sx={{ marginBottom: "0.5rem" }}>
                            {field.label}
                          </InputLabel>
                          <Select
                            name={field?.name}
                            value={value}
                            multiple={field?.isMulti}
                            disabled={field?.isReadOnly}
                            onChange={handleInternalCircularChange}
                          >
                            {field?.options?.map((option, idx) => (
                              <MenuItem
                                key={idx}
                                value={option?.value || option}
                              >
                                {option?.label || option}
                              </MenuItem>
                            ))}
                          </Select>
                          {!!error && <FormHelperText>{error}</FormHelperText>}
                        </FormControl>
                      ) : (
                        <TextField
                          fullWidth
                          label={field?.label}
                          name={field?.name}
                          type={field?.type || "text"}
                          InputLabelProps={field?.InputLabelProps}
                          value={value}
                          onChange={handleInternalCircularChange}
                          error={!!error}
                          helperText={error}
                          required
                          disabled={field?.isReadOnly}
                        />
                      )}
                    </Grid>
                  )
                );
              })}

              <Grid item xs={12}>
                <Typography mb={2} variant="subtitle1">
                  Description:
                </Typography>
                <TextField
                  fullWidth
                  multiline
                  rows={4}
                  name="description"
                  value={internalCircular.description}
                  onChange={handleInternalCircularChange}
                  error={!!internalCircularErrors.description}
                  helperText={internalCircularErrors.description}
                  required
                />
              </Grid>
            </Grid>
          </DialogContent>
          <Divider />

          <DialogActions sx={{ p: 2.5 }}>
            <Grid container justifyContent="flex-end">
              <Grid item>
                <Stack direction="row" spacing={2} alignItems="center">
                  <Button
                    color="error"
                    variant="contained"
                    onClick={(e) => handleInternalCircularModal(e)}
                  >
                    Cancel
                  </Button>
                  {feedBackResponce === null && (
                    <Button
                      color="success"
                      variant="contained"
                      type="submit"
                      onClick={() => handleInternalCircularSubmit()}
                    >
                      Submit
                    </Button>
                  )}
                </Stack>
              </Grid>
            </Grid>
          </DialogActions>
        </CustomDialog>
      )}
      {selectedModal === "agent" && (
        <CustomDialog
          open={selectedModal}
          onCancel={handleAgentModal}
          maxWidth={"md"}
        >
          <DialogTitle>Agent Information</DialogTitle>
          <Divider />
          <DialogContent sx={{ p: 2.5 }}>
            <Grid container spacing={2}>
              {[
                {
                  label: "Name",
                  name: "name",
                },
                {
                  label: "Address",
                  name: "address",
                },
              ].map((field, index) => {
                // Handle value extraction
                const value = agentData[field.name];

                // Handle error extraction
                const error = agentErrors[field.name];

                return (
                  <Grid item xs={12} sm={6} key={index}>
                    <TextField
                      fullWidth
                      label={field?.label}
                      name={field?.name}
                      value={value ?? ""}
                      onChange={handleAgentChange}
                      error={!!error}
                      helperText={error}
                      required
                    />
                  </Grid>
                );
              })}
            </Grid>
          </DialogContent>
          <Divider />

          <DialogActions sx={{ p: 2.5 }}>
            <Grid container justifyContent="flex-end">
              <Grid item>
                <Stack direction="row" spacing={2} alignItems="center">
                  <Button
                    color="error"
                    variant="contained"
                    onClick={handleAgentModal}
                  >
                    Cancel
                  </Button>

                  <Button
                    color="success"
                    variant="contained"
                    type="submit"
                    onClick={handleAgentSubmit}
                  >
                    Submit
                  </Button>
                </Stack>
              </Grid>
            </Grid>
          </DialogActions>
        </CustomDialog>
      )}
      {selectedModal === "generateOrderForm" && (
        <CustomDialog
          open={selectedModal}
          onCancel={(e) => handleGenerateOrderModal(e)}
          maxWidth={"md"}
        >
          <DialogTitle>Generate Order For Customer</DialogTitle>
          <Divider />
          <DialogContent sx={{ p: 2.5 }}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <TextField
                  fullWidth
                  label="Email"
                  name="email"
                  type="email"
                  value={generateOrderData?.email}
                  onChange={handleGenerateOrderChange}
                  required
                  error={generateOrderDataError}
                  helperText={
                    generateOrderDataError ? "Please enter a valid email" : ""
                  }
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  fullWidth
                  label="Mobile"
                  name="mobile"
                  value={generateOrderData?.mobile}
                  onChange={handleGenerateOrderChange}
                  placeholder="Optional"
                />
              </Grid>
            </Grid>
          </DialogContent>
          <Divider />
          <DialogActions sx={{ p: 2.5 }}>
            <Grid container justifyContent="flex-end">
              <Grid item>
                <Stack direction="row" spacing={2} alignItems="center">
                  <Button
                    color="error"
                    variant="contained"
                    onClick={(e) => handleGenerateOrderModal(e)}
                  >
                    Cancel
                  </Button>
                  <Button
                    color="success"
                    variant="contained"
                    type="submit"
                    onClick={() => handleSubmitOrder()}
                  >
                    Submit
                  </Button>
                </Stack>
              </Grid>
            </Grid>
          </DialogActions>
        </CustomDialog>
      )}
      <RecallModal open={openRecallModal} setOpen={setOpenRecallModal} />
    </>
  );
};

export default withRouter(MegaMenuSection);
