export const Constants = {
  KEY_SELECTED_ROLE: "selected_role",
  KEY_CAPA_DETAILS: "capaDetails",
  KEY_USER_DATA: "user_data",
  STANDARDNAME: "standardName",
  CHANGENOTEDATA: "changeNoteData",
  PERMISSIONDATA: "permissionData",
  DOCUMENT_NUMBERS: "documentNumbers",
  KEY_NOTIFICATION_SOUND: "notification_sound",
  KEY_NOTIFICATION_COUNT: "notification_count",
  KEY_USER_EMAIL: "user_email",
  KEY_USER_PASSWORD: "user_password",
  FIREBASE_ID: "firebase_id",
  LOCATION: "location",
  KEY_USER_ID: "user_id",
  KEY_USER_TOKEN: "user_token",
  KEY_COMPANY_DATA: "companyData",
  AGREEMENTDATA: "agreementData",
  CONFIGDATA: "configData",
  STANDARDLIST: "standardlists",
  USERLISTS: "userlists",
  KEY_HOST_NAME: "hostname",
  OUTOFRANGEFIELDS: "outOfRangeFields",
  KEY_USER_IMAGE_URL: "user_image_url",
  KEY_IS_VERIFIED: "is_verified",
  KEY_EMAIL_VERIFIED: "email_verified",
  TEMPID: "tempId",
  CONOSLE_SUCCESS: "\x1b[1m\x1b[42m\x1b[30m%s\x1b[0m",
  CONOSLE_ERROR: "\x1b[1m\x1b[41m\x1b[30m%s\x1b[0m",
  CONOSLE_REQUEST: "\x1b[1m\x1b[46m\x1b[30m%s\x1b[0m",
  SUCCESS: "success",
  ERROR: "error",
  KEY_RBAC_DOWNLOAD: "download",
  KEY_RBAC_CREATE: "create",
  KEY_RBAC_DELETE: "delete",
  KEY_RBAC_UPDATE: "update",
  KEY_RBAC_READ: "read",
  KEY_PERMISSION_DATA: "permissionData",
};
