import { lazy } from "react";
import Loadable from "components/Loadable";
import AuthGuard from "utils/route-guard/AuthGuard";
import MainLayout from "layout/MainLayout";

// Define lazy-loaded components
const OrderReviewFormById = Loadable(
  lazy(() => import("../OrderReviewFormById"))
);
const OrderFormHistory = Loadable(lazy(() => import("../OrderFormHistory")));
const ProductHistory = Loadable(lazy(() => import("../ProductHistory")));
const ViewById = Loadable(lazy(() => import("../ViewById")));
const AgentHistory = Loadable(lazy(() => import("../AgentHistory")));
const OrderHistory = Loadable(lazy(() => import("../OrderHistory")));
const AgentAgreement = Loadable(lazy(() => import("../AgentAgreement")));
const SalesHistory = Loadable(lazy(() => import("../SalesHistory")));
const SalesInformationForm = Loadable(
  lazy(() => import("../SalesInformationForm"))
);
const ProductDecision = Loadable(lazy(() => import("../ProductDecision")));
const DistributionRecord = Loadable(
  lazy(() => import("../DistributionRecord"))
);
const DistubutorsHistory = Loadable(
  lazy(() => import("../DistubutorsHistory"))
);
const List = Loadable(lazy(() => import("../List")));
const YearList = Loadable(lazy(() => import("../YearList")));
const YearWiseDocument = Loadable(lazy(() => import("../YearWiseDocument")));

// Helper function to create routes
const createRoute = (path, element, children) => ({ path, element, children });

// Define routes using lazy-loaded components
const CRPDRoutes = createRoute(
  "/",
  <AuthGuard>
    <MainLayout />
  </AuthGuard>,
  [
    createRoute("customer-related-process-and-distribution", null, [
      createRoute("order-review/:id", <OrderReviewFormById />),
      createRoute(":id/:phase?/:status?", <ViewById />),
      createRoute("sales-information/:id", <SalesInformationForm />),
      createRoute("agent-history/:year", <AgentHistory />),
      createRoute("order-history", <OrderHistory />),
      createRoute("order-form-history", <OrderFormHistory />),
      createRoute("sales-history", <SalesHistory />),
      createRoute("product-history", <ProductHistory />),
      createRoute("agreement/:id", <AgentAgreement />),
      createRoute("product-decision/:id", <ProductDecision />),
      createRoute("distribution-record/:id", <DistributionRecord />),
      createRoute("distributors-history/:year", <DistubutorsHistory />),
      createRoute("list/:year", <List />),
      createRoute("year-list", <YearList />),
      createRoute(
        "year-wise-document/:documentName/:year",
        <YearWiseDocument />
      ),
    ]),
  ]
);

export default CRPDRoutes;
